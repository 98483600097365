import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiTime } from 'react-icons/bi';
import { FiCodepen } from 'react-icons/fi';
import { SiCodepen, SiMicrosoftedge } from 'react-icons/si';
import { FaCropAlt } from 'react-icons/fa';
import chose from '../assets/choose.jpg';

const Choose = () => {
  const { t } = useTranslation();

  // Directly implementing the `choose` array inside the component
  const choose = [
    {
      name: 'choose.experienced_team.name',
      icon: <FaCropAlt className="h-10 w-10" />,
      summary: 'choose.experienced_team.summary'
    },
    {
      name: 'choose.reliable_service.name',
      icon: <FiCodepen className="h-10 w-10" />,
      summary: 'choose.reliable_service.summary'
    },
    {
      name: 'choose.competitive_pricing.name',
      icon: <SiCodepen className="h-10 w-10" />,
      summary: 'choose.competitive_pricing.summary'
    },
    {
      name: 'choose.customer_satisfaction.name',
      icon: <SiMicrosoftedge className="h-10 w-10" />,
      summary: 'choose.customer_satisfaction.summary'
    },
    {
      name: 'choose.fast_delivery.name',
      icon: <BiTime className="h-10 w-10" />,
      summary: 'choose.fast_delivery.summary'
    }
  ];

  return (
    <div className="relative flex flex-col items-center justify-center space-y-0 p-10 md:mx-5 choose text-white gap-7">
      {/* Wrapper for Background and Blur */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Blurred Background Image */}
        <div 
          className="absolute inset-0 z-0" 
          style={{ 
            backgroundImage: `url(${chose})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat',
            filter: 'blur(1px)' // Apply blur only to the background image
          }}
        ></div>

        {/* Transparent Overlay */}
        <div className="absolute inset-0 z-0 bg-green-700 opacity-50"></div>
      </div>

      {/* Content Section */}
      <div className="relative z-10 flex flex-col justify-center items-center">
        <h2 className="text-center text-white text-[30px]">{t('choose.title')}</h2>
        <p className="text-center text-zinc-200 max-w-lg text-[16px]">
          {t('choose.description')}
        </p>
      </div>

      {/* List Section */}
      <div className="relative z-10 sm:mx-5 md:mx-20 lg:mx-40 flex flex-col space-y-5">
        {choose.map((c, index) => (
          <div className="flex space-x-2 items-center" key={index}>
            {c.icon}
            <span>
              <p className="font-bold text-[18px]">{t(c.name)}</p>
              <p style={{ fontSize: '14px' }} className="text-zinc-200">
                {t(c.summary)}
              </p>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Choose;
