import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import Testimonial from "../components/Testimonial";
import Choose from '../components/Choose';
import About from "../components/about";
import Services from "../components/Services";
import Whoarewe from "../components/Whoarewe";
import { NavLink } from "react-router-dom";
import bg from "../logo/bg.jpg";
import bg1 from "../logo/bg1.jpg";
import bg2 from "../logo/bg2.jpg";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Home = () => {
  const { t } = useTranslation(); // Initialize translation
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [bg, bg1, bg2]; // The images array that rotates

  // Function to change background image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5000ms or 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <>
      <Navbar />
      <div className="relative">
        <div className="relative top-10">
          <div className="py-24 z-50 absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center text-white text-center">
            <h1 className="text-[44px] md:text-[64px] font-bold my-4 animate__animated animate__zoomIn">
              {t('home.ecoGreenProjects')}
            </h1>
            <p className="text-[20px] md:text-[26px] mb-3">
              {t('home.goGreenSaveGreen')}
            </p>
            <div className="flex gap-5 mt-14 z-500"> 
              <NavLink to="/form">
                <button className="p-3 font-bold px-8 border border-[#5b8251] bg-[#5b8251] text-white rounded-md hover:bg-[#6fb962] hover:border-[#6fb962] active:bg-[#5b8251] active:border-[#5b8251] transition duration-200">
                  {t('home.contactUs')}
                </button>
              </NavLink>
              <NavLink to="/services">
                <button className="p-3 font-bold px-8 border-2 border-[#ffffff] bg-transparent text-[#ffffff] rounded-md hover:bg-[#6fb962] hover:border-[#6fb962] hover:text-white active:bg-[#5b8251] active:border-[#5b8251] active:text-white transition duration-200">
                  {t('home.ourServices')}
                </button>
              </NavLink>
            </div>
          </div>

          {/* Image Rotation with Inline Styles */}
          <img
            className="h-[620px] w-full object-cover transition-opacity duration-1000 ease-in-out"
            style={{
              opacity: 1,
              transition: "opacity 1s ease-in-out",
            }}
            src={images[currentImageIndex]} // Correct reference to the `images` array
            alt="Plumbing background"
          />
          <div className="absolute bg-[#0f2b5b99] top-0 left-0 right-0 bottom-0 z-40" /> {/* Set a lower z-index */}
        </div>
        <div className="absolute top-50 bottom-0 left-0 right-0 z-40">
          
        </div>
      </div>
      <div className="bg-white shadow md:mx-5 rounded-md py-8 my-5">
        <About />
      </div>
      <div className="bg-white shadow md:mx-5 rounded-md py-8 my-5">
        <Services />
      </div>
      <div className="bg-white shadow md:mx-5 rounded-md py-8 my-5">
        <Whoarewe />
      </div>
      <div className="py-5 bg-white shadow rounded-md my-5 md:mx-5 px-3 md:px-0">
        <div className=" relative">
          <Choose />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
