import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Result from './pages/Result';
import About from './pages/About';
import Projectspage from './pages/Projectspage';
import Faqs from './pages/Faqs';
import { Helmet } from 'react-helmet';
import ContactButton from './functions/Contactbutton';
import Form from './pages/Form';
import ecologo from './assets/ecologo.png';

function App() {
  return (
    <>
      <Helmet>
        <title>Eco Green Projects</title>
        <meta
          name="description"
          content="Eco Green Projects specializes in solar energy solutions, air conditioning, pool heat pumps, and more. With over 15 years of experience, we bring sustainable living to Calp and surrounding areas."
        />
        <meta
          name="keywords"
          content="solar energy, solar panel installation, air conditioning, pool heat pumps, thermodynamic hot water tanks, electric terminals, sustainable living, Eco Green Projects, Calp, Altea, Moraira, Benissa, renewable energy solutions"
        />
        <meta name="author" content="Eco Green Projects" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Setting the favicon */}
        <link rel="icon" href={ecologo} type="image/png" />

        {/* For better SEO, you can add the logo for rich snippets */}
        <meta property="og:image" content={ecologo} />
        <meta property="og:title" content="Eco Green Projects" />
        <meta
          property="og:description"
          content="Eco Green Projects specializes in solar energy solutions and sustainable living for over 15 years."
        />
        <meta property="og:url" content="https://yourwebsite.com" />
      </Helmet>

      <div className="box-sizing:border-box bg-gray-100">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Result />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/form" element={<Form />} />
          <Route path="/projects" element={<Projectspage />} />
        </Routes>
        
      </div>
      <ContactButton />
    </>
    
  );
}

export default App;