import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import bg2 from "../assets/about.jpg";

const AboutUs = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="bg-white my-4 md:my-10 px-4 md:px-10 py-4 md:py-10">
      <div className="flex flex-col md:flex-row justify-between items-center gap-10">
        {/* Text Content */}
        <div className="w-full md:w-1/2 flex flex-col gap-6 p-4 md:p-10">
          <div className="text-left relative space">
            <h2 style={{
              color: "#5b8251",
              textAlign: "left",
              lineHeight: "1.5",
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "1.5rem"
            }}>
              <span
                className="block relative w-32 h-2 bg-[#5b8251] ml-[25px] rounded-md"
              ></span>
              {t('about.aboutUsTitle')}
            </h2>
          </div>
          <div className="text-left text-black space-y-4 leading-relaxed text-justify">
            <p className="text-[18px]">{t('about.aboutUsText1')}</p>
            <p className="text-[18px]">{t('about.aboutUsText2')}</p>
            <p className="text-[18px]">{t('about.aboutUsText3')}</p>
          </div>

          {/* Button Section */}
          <div className="mt-6 flex flex-col md:flex-row gap-4">
            <div className="view-all-btn1">
              <Link to="/form" onClick={scrollToTop}>
                <button
                  className="bg-[#6fb962] text-white px-6 py-2 rounded-md hover:bg-white hover:text-[#6fb962] hover:border-[#6fb962] border-transparent border-2 transition-all duration-300"
                >
                  {t('about.quoteButton')}
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* Background Image */}
        <div className="w-full md:w-1/2 md:h-[550px] bg-cover bg-center" style={{ backgroundImage: `url(${bg2})` }}>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
