import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './LanguageSwitcher.css';

// Import flag library
import Flag from 'react-world-flags';

const LanguageSwitcher = ({ onLanguageChange }) => {
  const { i18n } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const currentLanguage = localStorage.getItem('lang') || 'en';

  const changeLanguage = (lng) => {
    // Change language for i18n
    i18n.changeLanguage(lng);
    
    // Notify parent about language change
    onLanguageChange(lng);

    // Persist language in localStorage
    localStorage.setItem('lang', lng);

    // Emit storage event manually for real-time sync
    window.dispatchEvent(new Event('storage'));

    // Close the dropdown
    setShowOptions(false);
  };

  return (
    <div className={`language-switcher ${showOptions ? 'open' : ''}`}>
      {/* Button to toggle the dropdown */}
      <button
        onClick={() => setShowOptions(!showOptions)}
        className="language-button"
        aria-label="Change Language"
      >
        <FaGlobe className="globe-icon" />
      </button>

      {/* Language options dropdown */}
      {showOptions && (
        <div className="language-options">
          <button
            className={`language-option ${currentLanguage === 'en' ? 'active' : ''}`}
            onClick={() => changeLanguage('en')}
          >
            <Flag code="GB" className="flag-icon" /> English
          </button>
          <button
            className={`language-option ${currentLanguage === 'fr' ? 'active' : ''}`}
            onClick={() => changeLanguage('fr')}
          >
            <Flag code="FR" className="flag-icon" /> Français
          </button>
          <button
            className={`language-option ${currentLanguage === 'nr' ? 'active' : ''}`}
            onClick={() => changeLanguage('nr')}
          >
            <Flag code="NL" className="flag-icon" /> Nederlands
          </button>
          <button
            className={`language-option ${currentLanguage === 'al' ? 'active' : ''}`}
            onClick={() => changeLanguage('al')}
          >
            <Flag code="DE" className="flag-icon" /> Deutsch
          </button>
          <button
            className={`language-option ${currentLanguage === 'es' ? 'active' : ''}`}
            onClick={() => changeLanguage('es')}
          >
            <Flag code="ES" className="flag-icon" /> Español
          </button>
        </div>
      )}
    </div>
  );
};

LanguageSwitcher.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
};

export default LanguageSwitcher;
