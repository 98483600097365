import React from 'react';
import { Link } from 'react-router-dom';
import { MapIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import logo from '../assets/ecologo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="bg-[#5b8251] text-white flex flex-col pt-8 pb-4">
      <div className="container mx-auto px-4 md:px-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
{/* Logo and description */}
<div className="relative md:left-[-50px] md:top-[10px]">
  <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4">
    <img 
      src={logo} 
      alt="ECO Green Projects Logo" 
      className="h-28 w-28 mb-4 md:mr-4" 
    />
    <p className="!text-xl leading-relaxed max-w-[400px] text-left" style={{ fontSize: '21px' }}>
      {t('footer.description')}
    </p>
  </div>
</div>


          {/* Our Services */}
          <div className="relative md:right-[-50px]">
            <h2 className="font-bold text-lg mb-4">{t('footer.our_services')}</h2>
            <div className="flex flex-col text-sm space-y-2">
              <Link to="/services" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.solar_panel_installation')}
              </Link>
              <Link to="/services" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.air_conditioning_installation')}
              </Link>
              <Link to="/services" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.pool_heat_pump_installation')}
              </Link>
              <Link to="/services" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.electric_car_charging_station_installation')}
              </Link>
            </div>
          </div>

          {/* Our Presence in Southern Spain */}
          <div className="relative md:right-[-50px]">
            <h2 className="font-bold text-lg mb-4">{t('footer.our_presence')}</h2>
            <div className="flex flex-col text-sm space-y-2">
              <Link to="/projects" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.implementation_in_calpe')}
              </Link>
              <Link to="/projects" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.implementation_in_altea')}
              </Link>
              <Link to="/projects" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.implementation_in_moraira')}
              </Link>
              <Link to="/projects" onClick={scrollToTop} className="hover:text-[#6fb962] transition-colors duration-300">
                {t('footer.implementation_in_benissa')}
              </Link>
            </div>

            {/* Gap between sections */}
            <div className="mt-6"></div>

            {/* Contact Information */}
            <div className='text-white'>
              <h2 className='font-bold text-lg mb-4'>{t('footer.contact')}</h2>
              <address className='flex flex-col space-y-3 not-italic'>
                <span className='text-sm flex items-center'>
                  <EnvelopeIcon className='text-[#6fb962] h-5 w-5 mx-1' />
                  <a href='mailto:info@ecogreenprojects.net' className='hover:text-[#6fb962] transition-colors duration-300'>
                    info@ecogreenprojects.net
                  </a>
                </span>
                <span className='text-sm flex items-center'>
                  <MapIcon className='text-[#6fb962] h-5 w-5 mx-1' />
                  <a
                    href="https://www.google.com/maps?q=Partida+Les+Cucarres+03710+CALPE"
                    target="_blank"
                    rel="noopener noreferrer"
                    className='hover:text-[#6fb962] transition-colors duration-300'
                  >
                    Partida Les Cucarres 03710 CALPE
                  </a>
                </span>
                <span className='text-sm flex items-center'>
                  <PhoneIcon className='text-[#6fb962] h-5 w-5 mx-1' />
                  <a href="tel:+3469092639" className='hover:text-[#6fb962] transition-colors duration-300'>
                    +34 6 90 92 63 95
                  </a>
                </span>
              </address>
            </div>
          </div>

        </div>
      </div>

      {/* Social Media Links */}
      <div className='flex items-center justify-between p-4 connect px-6 bg-[#4e6f43]'>
        <h2 className='font-bold text-white text-lg'>{t('footer.follow_us')}</h2>
        <div className='flex space-x-4 flex-grow justify-center'>
          <a href='https://www.facebook.com' target='_blank' rel='noopener noreferrer' className='hover:scale-110 transition-transform duration-300'>
            <FaFacebook className='text-white h-6 w-6 hover:text-[#6fb962] transition-colors duration-300' />
          </a>
          <a href='https://www.instagram.com' target='_blank' rel='noopener noreferrer' className='hover:scale-110 transition-transform duration-300'>
            <FaInstagram className='text-white h-6 w-6 hover:text-[#6fb962] transition-colors duration-300' />
          </a>
          <a href='https://www.linkedin.com' target='_blank' rel='noopener noreferrer' className='hover:scale-110 transition-transform duration-300'>
            <FaLinkedin className='text-white h-6 w-6 hover:text-[#6fb962] transition-colors duration-300' />
          </a>
        </div>
      </div>

      {/* Copyright Section */}
      <div className='flex flex-col md:flex-row justify-between items-center bg-[#333] text-white text-sm p-4'>
        <span className='mb-2 md:mb-0'>© 2024 Eco Green Projects.</span>
        <div className='flex space-x-4'>
          <Link to='/' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>
            {t('footer.terms_conditions')}
          </Link>
          <Link to='/' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>
            {t('footer.privacy_policy')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
