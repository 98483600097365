export const resources = {
  en: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projects",
        goGreenSaveGreen: "Go Green & Save Green with Solar Energy",
        contactUs: "CONTACT US",
        ourServices: "OUR SERVICES"
      },
      about: {
        aboutUsTitle: "About Us",
        aboutUsText1: "Eco Green Projects, founded by Sébastien, who fell in love with the breathtaking landscapes of Calpe, has a mission to enable locals and expatriates to embrace a sustainable lifestyle through solar solutions.",
        aboutUsText2: "Imagine reducing your electricity bill by 85%! Our solar panel installations are not just a smart choice; they are a real change for your wallet and the environment.",
        aboutUsText3: "With over 15 years of experience in the solar panel industry in Belgium, where we have completed numerous installations, we now bring our expertise to the sunny coasts of Calpe, Altea, Moraira, Benissa, and beyond.",
        quoteButton: "Request a free quote"
      },
      services: {
        ourServicesTitle: "Our Services",
        solarPanelInstallation: "Solar Panel Installation",
        airConditioningInstallation: "Air Conditioning Installation",
        poolHeatPumpInstallation: "Pool Heat Pump Installation",
        heatPumpBoilerInstallation: "Heat Pump Boiler Installation",
        electricCarChargingStation: "Electric vehicle charging station",
        serviceDetailButton: "Our services in detail",
        freeQuoteButton: "Ask for a free quote",
        friendlyPersonalService: "Friendly & Personal Service",
        installationExperience: "Wealth of Experience in Installation & Maintenance",
        available: "Available 24/7"
      },
      ourmission: {
        title: "Our Mission",
        paragraph1: "At Eco Green Projects, we are committed to transforming how you perceive energy. With a mission centered on budget savings through solar energy while promoting eco-responsibility, we strive to make sustainable living accessible to everyone. Our core values of innovation, smart solutions, and sustainability guide us in every project we undertake.",
        paragraph2: "With over 15 years of experience in the solar energy field and numerous successful installations, we have established ourselves as a trusted leader in this sector. Our passion for renewable energy is rooted in our love for Calpe, a city blessed with 300 days of sunshine per year, making it an ideal place for solar panel installations.",
        paragraph3: "We offer a range of services, including sustainable installations and timely completed projects, designed to help you save money while enjoying the benefits of clean energy. Our customer-focused approach caters to both expatriates and local residents, addressing the unique needs of our diverse clientele.",
        viewProjects: "View our projects",
        askQuote: "Request a free quote"
      },
      
      choose: {
        title: "Why Choose Us",
        description: "Choosing us means you will be getting a reliable and experienced professional who has the expertise to deliver quality services.",
        experienced_team: {
          name: "Experienced and Skilled team",
          summary: "Our company team is highly experienced and skilled and well-equipped to handle any issue you may be facing"
        },
        reliable_service: {
          name: "Reliable and Efficient Service",
          summary: "We strive to provide reliable and efficient service to our customers. We arrive on time, complete the work quickly and efficiently"
        },
        competitive_pricing: {
          name: "Competitive Pricing",
          summary: "We offer competitive pricing without compromising on the quality of our work"
        },
        customer_satisfaction: {
          name: "Customer Satisfaction",
          summary: "We listen to your needs and concerns, and we work with you to find the best solution"
        },
        fast_delivery: {
          name: "Fast Delivery",
          summary: "We are quick on delivering quotes and installations"
        }
      },
      navbar: {
        home: "HOME",
        about: "ABOUT US",
        services: "SERVICES",
        projects: "PROJECTS",
        faqs: "FAQS",
        contact: "CONTACT",
        ask_for_quote: "Ask For a Free Quote"
      },
      footer: {
        description: "Eco Green Projects is your partner and expert on solar energy for your project in Southern Spain. Our mission is to help you save money through our installations.",
        our_services: "Our Services",
        solar_panel_installation: "Solar Panel Installation",
        air_conditioning_installation: "Air Conditioning Installation Solutions",
        pool_heat_pump_installation: "Pool Heat Pump Installation",
        electric_car_charging_station_installation: "Electric Car Charging Station Installation",
        contact: "Contact",
        follow_us: "Follow Us",
        our_presence: "Our Presence in Southern Spain",
        implementation_in_calpe: "Implementation in Calpe",
        implementation_in_altea: "Implementation in Altea",
        implementation_in_moraira: "Implementation in Moraira",
        implementation_in_benissa: "Implementation in Benissa",
        terms_conditions: "Terms and Conditions",
        privacy_policy: "Privacy Policy"
      },
      aboutpage: {
        title: "About Us",
        heading: "At Eco Green Projects, we are committed to transforming the way you harness energy.",
        founder_info: "Founded by Sebastien, who fell in love with the breathtaking landscapes of Calpe, our mission is to empower locals and expats to embrace sustainable living through solar energy solutions.",
        savings: "Imagine reducing your electricity bill by an incredible 85%! Our solar panel installations are not only a smart choice, they are a game changer for your wallet and the environment.",
        experience: "For over 15 years, we have been well established in the solar panel industry in Belgium, having successfully completed a number of installations. Today, we bring our expertise to the sunny coasts of Calpe, Altea, Moraira, Benissa, and beyond.",
        services_title: "Our full range of services includes:",
        services: [
          "Solar panel installation: maximize your energy savings with our cutting-edge solar technology.",
          "Air conditioning solutions: keep your home cool and comfortable during the hot summer months.",
          "Pool Heat Pumps: Enjoy your pool all year round with energy-efficient heating solutions.",
          "Thermodynamic Hot Water Tanks: Harness renewable energy for your hot water needs.",
          "Electric Terminals: Ensure your home is equipped with the latest electrical solutions."
        ],
        closing: "Join us in our commitment to creating an eco-friendly environment while saving money. Let Eco Green Projects guide you on your journey to sustainable living. Contact us today to find out how we can help you switch to solar energy and enjoy the benefits of a greener lifestyle!"
      },
      result: {
        title: "Our Services",
        services: [
          {
            title: "Solar Panel Installation",
            description: "<span style=display:block;><strong>At Eco Green Projects, we provide professional solar panel installation services to help you harness renewable energy and reduce your energy bills.</strong></span></br><span style=display:block;> Our certified technicians ensure expert installation using high-quality solar panels to guarantee maximum efficiency and minimal disruption to your daily life.</span></br><span style=display:block;> Join the green energy revolution and choose sustainable and cost-effective energy solutions today.</span>",
            button: "Request a Free Quote",
            altText: "Solar Panel Installation"
          },
          {
            title: "Air Conditioning Installation Solutions",
            description: "<span style=display:block;><strong>At Eco Green Projects, we offer professional air conditioning installation services to ensure optimal comfort for your home or business.</strong></span></br><span style=display:block;> Our certified HVAC team uses high-quality units and the latest technology to deliver efficient and energy-saving cooling solutions tailored to your needs.</span></br><span style=display:block;> Enhance your indoor environment and enjoy perfectly controlled climate year-round.</span>",
            button: "Request a Free Quote",
            altText: "Air Conditioning Installation"
          },
          {
            title: "Swimming Pool Heat Pump Installation",
            description: "<span style=display:block;><strong>At Eco Green Projects, we provide professional swimming pool heat pump installation solutions to help extend your swimming season throughout the year.</strong></span></br><span style=display:block;> Our energy-efficient heat pumps are tailored to your pool's size and needs, ensuring optimal performance and energy savings.</span></br><span style=display:block;> Extend your pool usage with eco-friendly heating solutions that lower energy costs.</span>",
            button: "Request a Free Quote",
            altText: "Swimming Pool Heat Pump Installation"
          },
          {
            title: "Heat Pump Water Heater Installation",
            description: "<span style=display:block;><strong>At Eco Green Projects, we offer efficient and durable heat pump water heater installations for homes and businesses.</strong></span></br><span style=display:block;> Our certified professionals use cutting-edge heat pump technologies to reduce energy costs and minimize your carbon footprint.</span></br><span style=display:block;> Enjoy hassle-free installation and adopt energy-efficient consumption that supports a greener future.</span>",
            button: "Request a Free Quote",
            altText: "Heat Pump Water Heater Installation"
          },
          {
            title: "Electric Vehicle Charging Station Installation",
            description: "<span style=display:block;><strong>At Eco Green Projects, we specialize in installing electric vehicle (EV) charging stations to support sustainable transportation.</strong></span></br><span style=display:block;> Our team of experts ensures efficient and reliable installations using high-quality equipment, guaranteeing optimal performance with minimal disruption to your daily life.</span></br><span style=display:block;> Transition to a greener future with our EV charging solutions tailored for your home or business.</span>",
            button: "Request a Free Quote",
            altText: "Electric Vehicle Charging Station Installation"
          }
        ]
      },        
      project: {
        title: "Our Projects",
        projectTitle: "OUR PROJECT IN CALPE",
        description1: "We are pleased to present our inaugural project in Calpe.",
        description2: "This project includes a complete installation of solar panels and an inverter, equipped with a battery system. Additionally, we have implemented air conditioning, a pool heat pump, a thermodynamic water heater, and an electric vehicle charging station to enhance the property's sustainability and efficiency.",
        energyGraph: "Energy Consumption Graph",
        installationOverview: "Installation Overview"
    },    
      
        "faqTitle": "FAQ: Solar Energy Services and Installations in Southern Spain",
        "faq1": {
          "title": "How much does it cost to install solar panels?",
          "content": "The cost of a solar panel installation can vary depending on several factors such as the orientation of your home, the available surface area for the panels, and your energy needs. For homeowners in areas like Calpe, Altea, and Benissa, prices typically range between €5,000 and €12,000 for a complete installation. You'll benefit from significant long-term savings on your energy bills. To get an accurate quote tailored to your home and needs, we offer free and non-binding estimates."
        },
        "faq2": {
          "title": "What is the ROI (Return on Investment) for solar panels in Spain?",
          "content": "The ROI for solar panels in Spain is excellent, especially along the Costa Blanca, where the weather offers 300 days of sunshine per year. Most homeowners see a return on investment within 4 to 5 years, depending on the system size and energy consumption. With the addition of batteries, savings can be even greater due to reduced reliance on the power grid. Solar energy can reduce your electricity bills by up to 85%, making it a profitable long-term investment."
        },
        "faq3": {
          "title": "Why choose Eco Green Projects for solar panel installation?",
          "content": "With 15 years of experience in Belgium, we have built a reputation for quality and reliability. We bring the same level of expertise to southern Spain, serving areas such as Calpe, Altea, Benissa, Moraira, and beyond. Our key advantages include:<ul><li>Quick responses: We provide quotes promptly, so you don’t have to wait.</li><li>Efficient installation: We complete installations faster than most competitors, minimizing disruptions.</li><li>Multilingual support: Our team communicates in English, Spanish, French, and Dutch, making it easier for expatriates.</li></ul>We are your trusted partner for solar energy solutions on the Costa Blanca."
        },
        "faq4": {
          "title": "How long does it take to install solar panels?",
          "content": "We strive to complete solar panel installations quickly. Generally, the process takes between 1 and 3 days, depending on the system size and installation complexity. Once you have received a quote and decided to proceed, we can usually schedule the installation promptly for properties in Calpe, Altea, Moraira, Benissa, and surrounding areas. Our goal is to make the process as quick and hassle-free as possible."
        },
        "faq5": {
          "title": "How can I get a quote for solar panel installation?",
          "content": "Getting a free quote for solar panel installation is easy. You can:<ul><li>Call or email us, and our team will arrange a consultation.</li><li>Fill out the form on our website, and we will get back to you within 24 hours.</li></ul>We provide personalized quotes based on your energy needs and property specifications, ensuring you get an accurate and competitive price."
        },
        "faq6": {
          "title": "What types of installations do you offer?",
          "content": "We specialize in a variety of energy solutions, including:<ul><li>Solar panel installation: Efficient and cost-effective systems tailored to your home.</li><li>Air conditioning installation: Energy-efficient units that keep your home comfortable year-round.</li><li>Heat pump installation for swimming pools: Extend your swimming season with eco-friendly heating systems.</li><li>Thermodynamic water heater installation: Sustainable hot water preparation for your home.</li><li>Electric vehicle charging station installation: Convenient at-home charging solutions for your EV.</li></ul>Each service is available in Calpe, Altea, Moraira, Benissa, and surrounding regions."
        },
        "faq7": {
          "title": "Which areas do you serve?",
          "content": "We proudly serve homeowners and expatriates throughout the Costa Blanca, including:<ul><li>Calpe</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Whether you're looking for solar energy solutions, air conditioning installation, or electric vehicle charging stations, we deliver high-quality and reliable services across the region."
        
      },
      
  contactButtonText: "Your quote in few clicks!"
      
    
    }
  },
  fr: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projects",
        goGreenSaveGreen: "Adoptez l'énergie solaire et économisez",
        contactUs: "CONTACTEZ-NOUS",
        ourServices: "NOS SERVICES"
      },
      about: {
        aboutUsTitle: "À notre propos",
        aboutUsText1: "Eco green projects, fondé par Sébastien, qui est tombé amoureux des paysages à couper le souffle de Calpe, notre mission est de permettre aux habitants et aux expatriés d'adopter un mode de vie durable grâce aux solutions solaires. ",
        aboutUsText2: "Imaginez réduire votre facture d'électricité de 85%! Nos installations de panneaux solaires ne sont pas seulement un choix intelligent, elles sont un véritable changement pour votre porte-monnaie et pour l'environnement. ",
        aboutUsText3: "Avec plus de 15 ans d'expérience dans l'industrie des panneaux solaires en Belgique, où nous avons réalisé de nombreuses installations, nous apportons aujourd'hui notre expertise sur les côtes ensoleillées de Calpe, Altea, Moraira, Benissa et au-delà. ",
        quoteButton: "Demandez un devis gratuit"
      },
      services: {
        ourServicesTitle: "Nos Services",
        solarPanelInstallation: "Installation de panneaux solaires",
        airConditioningInstallation: "Installation de climatisation",
        poolHeatPumpInstallation: "Installation de pompe à chaleur pour piscine",
        heatPumpBoilerInstallation: "Installation de Boiler thermodynamique",
        electricCarChargingStation: "Borne de recharge pour véhicules",
        serviceDetailButton: "Nos services en détail",
        freeQuoteButton: "Demandez un devis gratuit",
        friendlyPersonalService: "Service amical et personnalisé",
        installationExperience: "Grande expérience en installation et maintenance",
        available: "Disponible 24/7"
      },
      ourmission: {
        title: "Notre mission",
        paragraph1: "Chez Eco Green Projects, nous nous engageons à transformer la façon dont vous percevez l'énergie. Avec une mission centrée sur les économies budgétaires grâce à l'énergie solaire tout en favorisant l'éco-responsabilité, nous nous efforçons de rendre la vie durable accessible à tous. Nos valeurs fondamentales d'innovation, de solutions intelligentes et de durabilité nous guident dans chaque projet que nous entreprenons.",
        paragraph2: "Fort de plus de 15 ans d'expérience dans le domaine de l'énergie solaire et de nombreuses installations réussies, nous nous sommes établis comme un leader de confiance dans ce secteur. Notre passion pour les énergies renouvelables est enracinée dans notre amour pour Calpe, une ville bénie par 300 jours de soleil par an, ce qui en fait un endroit idéal pour l'installation de panneaux solaires. ",
        paragraph3: "Nous proposons une gamme de services, incluant des installations durables et des projets achevés dans les délais, conçus pour vous aider à économiser de l'argent tout en bénéficiant des avantages de l'énergie propre. Notre approche axée sur le client s'adresse à la fois aux expatriés et aux habitants locaux afin de répondre aux besoins uniques de notre clientèle diversifiée. ",
        viewProjects: "Voir nos projets",
        askQuote: "Demander un devis gratuit"
      },
      choose: {
        title: "Pourquoi nous choisir",
        description: "Nous vous offrons un professionnel fiable et expérimenté pour vous fournir des services de qualité.",
        experienced_team: {
          name: "Équipe expérimentée et qualifiée",
          summary: "Notre équipe est hautement expérimentée et qualifiée, prête à résoudre tous les problèmes que vous pourriez rencontrer"
        },
        reliable_service: {
          name: "Service fiable et efficace",
          summary: "Nous nous efforçons de fournir un service fiable et efficace à nos clients. Nous arrivons à l'heure, terminons rapidement et efficacement"
        },
        competitive_pricing: {
          name: "Tarification compétitive",
          summary: "Nous offrons des prix compétitifs sans compromettre la qualité de notre travail"
        },
        customer_satisfaction: {
          name: "Satisfaction client",
          summary: "Nous écoutons vos besoins et préoccupations, et nous travaillons avec vous pour trouver la meilleure solution"
        },
        fast_delivery: {
          name: "Livraison rapide",
          summary: "Nous sommes rapides pour livrer des devis et des installations"
        }
      },
      navbar: {
        home: "ACCUEIL",
        about: "À NOTRE PROPOS",
        services: "SERVICES",
        projects: "PROJETS",
        faqs: "FAQ",
        contact: "CONTACT",
        ask_for_quote: "Demander un devis gratuit"
      },
      footer: {
        description: "Eco Green Projects est votre partenaire et expert en énergie solaire pour votre projet dans le sud de l'Espagne. Notre mission est de vous aider à économiser grâce à nos installations.",
        our_services: "Nos Services",
        solar_panel_installation: "Installation de Panneaux Solaires",
        air_conditioning_installation: "Solutions d'Installation de Climatisation",
        pool_heat_pump_installation: "Installation de Pompe à Chaleur pour Piscine",
        electric_car_charging_station_installation: "Installation de Borne de Recharge pour Voitures Électriques",
        contact: "Contact",
        follow_us: "Suivez-nous",
        our_presence: "Notre Présence dans le Sud de l'Espagne",
        implementation_in_calpe: "Mise en œuvre à Calpe",
        implementation_in_altea: "Mise en œuvre à Altea",
        implementation_in_moraira: "Mise en œuvre à Moraira",
        implementation_in_benissa: "Mise en œuvre à Benissa",
        terms_conditions: "Termes et Conditions",
        privacy_policy: "Politique de Confidentialité"
      },
      aboutpage: {
        title: "À notre propos",
        heading: "Chez Eco Green Projects, nous nous engageons à transformer la façon dont vous utilisez l'énergie.",
        founder_info: "Eco green projects, fondé par Sébastien, qui est tombé amoureux des paysages à couper le souffle de Calpe, notre mission est de permettre aux habitants et aux expatriés d'adopter un mode de vie durable grâce aux solutions solaires. ",
        savings: "Imaginez réduire votre facture d'électricité de 85%! Nos installations de panneaux solaires ne sont pas seulement un choix intelligent, elles sont un véritable changement pour votre porte-monnaie et pour l'environnement. ",
        experience: "Avec plus de 15 ans d'expérience dans l'industrie des panneaux solaires en Belgique, où nous avons réalisé de nombreuses installations, nous apportons aujourd'hui notre expertise sur les côtes ensoleillées de Calpe, Altea, Moraira, Benissa et au-delà. ",
        services_title: "Nos services complets comprennent :",
        services: [
          "Installation de panneaux solaires : maximisez vos économies d'énergie avec notre technologie solaire de pointe.",
          "Solutions de climatisation : gardez votre maison fraîche et confortable pendant les mois chauds.",
          "Pompes à chaleur pour piscine : profitez de votre piscine toute l'année grâce à des solutions de chauffage écoénergétiques.",
          "Réservoirs d'eau chaude thermodynamiques : exploitez l'énergie renouvelable pour vos besoins en eau chaude.",
          "Bornes électriques : assurez-vous que votre maison est équipée des dernières solutions électriques."
        ],
        closing: "Rejoignez-nous dans notre engagement pour créer un environnement écologique tout en économisant de l'argent. Laissez Eco Green Projects vous guider dans votre parcours vers un mode de vie durable. Contactez-nous dès aujourd'hui pour savoir comment nous pouvons vous aider à passer à l'énergie solaire et profiter des avantages d'un mode de vie plus vert!"
      },
      result: {
        title: "Nos Services",
        services: [
          {
            title: "Installation de panneaux solaires",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous proposons des services professionnels d'installation de panneaux solaires pour vous aider à exploiter l'énergie renouvelable et réduire vos factures d'énergie.</strong></span></br><span style=display:block;> Nos techniciens certifiés assurent une installation experte en utilisant des panneaux solaires de haute qualité pour garantir un maximum d'efficacité et un minimum de perturbations dans votre quotidien.</span></br><span style=display:block;> Rejoignez la révolution de l'énergie verte et faites le choix de l'énergie durable et rentable dès aujourd'hui.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de panneaux solaires"
          },
          {
            title: "Solutions d'installation de climatisation",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous proposons des services professionnels d'installation de climatisation pour garantir un confort optimal pour votre maison ou votre entreprise.</strong></span></br><span style=display:block;> Notre équipe certifiée HVAC utilise des unités de haute qualité et les dernières technologies pour offrir des solutions de refroidissement efficaces et économes en énergie, adaptées à vos besoins.</span></br><span style=display:block;> Améliorez votre environnement intérieur et profitez d'un climat parfaitement contrôlé tout au long de l'année.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de climatisation"
          },
          {
            title: "Installation de pompes à chaleur pour piscine",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous proposons des solutions professionnels d'installation de pompes à chaleur pour piscine afin de vous aider à prolonger la période de baignade durant l'année. </strong></span></br><span style=display:block;> Nos pompes à chaleur éco-énergétiques sont adaptées à la taille et aux besoins de votre piscine, garantissant des performances optimales et des économies d'énergie. </span></br><span style=display:block;> Prolongez la baignade dans votre piscine avec des solutions de chauffage écologiques qui réduisent les coûts énergétiques. </span>",
            button: "Demander un devis gratuit",
            altText: "Installation de pompes à chaleur pour piscine"
          },
          {
            title: "Installation de ballon sanitaire à pompe à chaleur ",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous fournissons des installations de ballons sanitaires à pompe à chaleur efficaces et durables pour les maisons et les entreprises. </strong></span></br><span style=display:block;> Nos professionnels certifiés utilisent des technologies de pompe à chaleur de pointe pour réduire les coûts énergétiques et minimiser votre empreinte carbone.</span></br><span style=display:block;> Profitez d'une installation sans tracas et adoptez une consommation économe en énergie qui soutient un avenir plus vert </span>",
            button: "Demander un devis gratuit",
            altText: "Installation de ballon sanitaire à pompe à chaleur "
          },
          {
            title: "Installation de bornes de recharge pour voitures électriques",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous nous spécialisons dans l'installation de bornes de recharge pour véhicules électriques (VE) afin de soutenir le transport durable.</strong></span></br><span style=display:block;> Notre équipe d'experts réalise des installations efficaces et fiables avec des équipements de haute qualité, garantissant des performances optimales et un minimum de perturbations dans votre quotidien.</span></br><span style=display:block;> Passez à un avenir plus vert avec nos solutions de recharge VE adaptées à votre maison ou à votre entreprise.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de bornes de recharge pour voitures électriques"
          }
        ]
      },
      
      project: {
        title: "Nos Projets",
        projectTitle: "NOTRE PROJET À CALPE",
        description1: "Nous sommes heureux de vous présenter notre projet inaugural à Calpe.",
        description2: "Ce projet comprend une installation de panneaux solaires et onduleur complète et équipée d'un système de batteries. De plus, nous avons mis en place la climatisation, une pompe à chaleur piscine, une ballon sanitaire thermodynamique et une borne de recharge pour véhicules électriques afin d'améliorer la durabilité et l'efficacité de la propriété. ",
        energyGraph: "Graphique de Consommation d'Énergie",
        installationOverview: "Aperçu de l'Installation"
      },
      "faqTitle": "FAQ : Services d'énergie solaire et d'installation dans le sud de l'Espagne",
      
        "faq1": {
          "title": "Combien coûte l'installation de panneaux solaires ?",
          "content": "Le coût d’une installation de panneaux solaires peut varier en fonction de plusieurs facteurs comme l’orientation de votre maison, la surface disponible pour la pose des panneaux et vos besoins en énergie. Pour les propriétaires dans des zones comme Calpe, Altea et Benissa, les prix varient généralement entre 5 000 € et 12 000 € pour une installation complète. Vous bénéficiez d'économies importantes à long terme sur vos factures d'énergie. Pour obtenir un prix précis adapté à votre maison et à vos besoins, nous offrons des devis gratuits et sans engagement."
        },
        "faq2": {
          "title": "Quel est le ROI (Retour sur Investissement) pour les panneaux solaires en Espagne ?",
          "content": "Le ROI pour les panneaux solaires en Espagne est excellent, surtout le long de la Costa Blanca, où la météo offre 300 jours de soleil par an. La plupart des propriétaires voient un retour sur investissement endéans 4 à 5 ans, en fonction de la taille du système et de la consommation d'énergie. Avec l’ajout de batteries, les économies peuvent être encore plus importantes en raison de la réduction de la dépendance au réseau d’approvisionnement. L'énergie solaire peut réduire vos factures d'électricité jusqu'à 85 %, ce qui en fait un investissement rentable à long terme."
        },
        "faq3": {
          "title": "Pourquoi choisir Eco Green Projects pour l'installation de panneaux solaires ?",
          "content": "Fort de 15 ans d'expérience en Belgique, nous avons acquis une réputation de qualité et de fiabilité. Nous apportons ce même niveau d'expertise dans le sud de l'Espagne, en desservant les zones telles que Calpe, Altea, Benissa, Moraira et au-delà. Nos avantages clés incluent :<ul><li>Réponses rapides : Nous fournissons des devis rapidement, vous n'aurez donc pas à attendre.</li><li>Installation efficace : Nous réalisons les installations plus rapidement que la plupart de nos concurrents, minimisant ainsi les perturbations.</li><li>Soutien multilingue : Notre équipe communique en anglais, espagnol, français et néerlandais, facilitant ainsi la communication avec les expatriés.</li></ul>Nous sommes votre partenaire de confiance pour des solutions énergétiques solaires sur la Costa Blanca."
        },
        "faq4": {
          "title": "Combien de temps faut-il pour installer des panneaux solaires ?",
          "content": "Nous nous efforçons de réaliser des installations de panneaux solaires rapidement. En général, le processus prend entre 1 et 3 jours, selon la taille du système et la complexité de l'installation. Une fois que vous avez reçu un devis et décidé de poursuivre, nous pouvons généralement planifier l'installation dans un court délai pour les propriétés à Calpe, Altea, Moraira, Benissa et les environs. Notre objectif est de rendre le processus aussi rapide et sans tracas que possible."
        },
        "faq5": {
          "title": "Comment obtenir un devis pour l'installation de panneaux solaires ?",
          "content": "Obtenir un devis gratuit pour l'installation de panneaux solaires est facile. Vous pouvez :<ul><li>Nous appeler ou nous envoyer un e-mail, et notre équipe organisera une consultation.</li><li>Remplir le formulaire sur notre site Web, et nous vous répondrons dans les 24 heures.</li></ul>Nous proposons des devis personnalisés en fonction de vos besoins en énergie et des spécifications de votre propriété, vous garantissant ainsi un prix précis et compétitif."
        },
        "faq6": {
          "title": "Quels types d'installations proposez-vous ?",
          "content": "Nous sommes spécialisés dans une variété de solutions énergétiques, y compris :<ul><li>Installation de panneaux solaires : des systèmes efficaces et rentables adaptés à votre domicile.</li><li>Installation de climatisation : des unités écoénergétiques qui maintiennent votre maison confortable tout au long de l'année.</li><li>Installation de pompe à chaleur pour piscine : prolongez votre saison de baignade avec un système de chauffage écologique.</li><li>Installation de ballons thermodynamiques : une préparation de votre eau chaude sanitaire durable pour l'approvisionnement de votre maison.</li><li>Installation de borne de recharge pour voiture électrique : des solutions pratiques de recharge à domicile pour votre véhicule électrique.</li></ul>Chaque service est disponible à Calpe, Altea, Moraira, Benissa et dans les régions environnantes."
        },
        "faq7": {
          "title": "Quelles zones desservez-vous ?",
          "content": "Nous servons fièrement les propriétaires et expatriés dans toute la Costa Blanca, y compris :<ul><li>Calpe</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Que vous recherchiez des solutions énergétiques solaires, l'installation de climatiseurs ou des bornes de recharge pour véhicules électriques, nous fournissons des services de haute qualité et fiables dans toute la région."
        
      
      
  },
    contactButtonText: "Votre devis en quelques clics !"

    }
  },
  nr: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projects",
        goGreenSaveGreen: "Ga groen & bespaar groen met zonne-energie",
        contactUs: "NEEM CONTACT OP",
        ourServices: "ONZE DIENSTEN"
      },
      about: {
        aboutUsTitle: "Over Ons",
        aboutUsText1: "Eco Green Projects, opgericht door Sébastien, die verliefd werd op de adembenemende landschappen van Calpe, heeft als missie om lokale bewoners en expats in staat te stellen een duurzame levensstijl te omarmen met behulp van zonne-oplossingen.",
        aboutUsText2: "Stel je voor dat je je energierekening met 85% verlaagt! Onze installatie van zonnepanelen is niet alleen een slimme keuze; het is een echte verandering voor je portemonnee en het milieu.",
        aboutUsText3: "Met meer dan 15 jaar ervaring in de zonnepaneelindustrie in België, waar we talrijke installaties hebben uitgevoerd, brengen we onze expertise nu naar de zonnige kusten van Calpe, Altea, Moraira, Benissa en daarbuiten.",
        quoteButton: "Vraag een gratis offerte aan"
      },      
      services: {
        ourServicesTitle: "Onze Diensten",
        solarPanelInstallation: "Zonnepaneelinstallatie",
        airConditioningInstallation: "Airconditioninginstallatie",
        poolHeatPumpInstallation: "Installatie van zwembadwarmtepomp",
        heatPumpBoilerInstallation: "Installatie van een thermodynamische boiler",
        electricCarChargingStation: "Laadpaal voor elektrische voertuigen",
        serviceDetailButton: "Onze diensten in detail",
        freeQuoteButton: "Vraag een gratis offerte aan",
        friendlyPersonalService: "Vriendelijke en persoonlijke service",
        installationExperience: "Veel ervaring in installatie en onderhoud",
        available: "24/7 beschikbaar"
      },
      ourmission: {
        title: "Onze Missie",
        paragraph1: "Bij Eco Green Projects zijn we toegewijd aan het transformeren van de manier waarop u energie ervaart. Met een missie gericht op budgetbesparingen door middel van zonne-energie en het bevorderen van milieubewustzijn, streven we ernaar duurzaam leven voor iedereen toegankelijk te maken. Onze kernwaarden innovatie, slimme oplossingen en duurzaamheid begeleiden ons in elk project dat we ondernemen.",
        paragraph2: "Met meer dan 15 jaar ervaring in de zonne-energiesector en talloze succesvolle installaties, hebben we ons gevestigd als een vertrouwde leider in deze sector. Onze passie voor hernieuwbare energie is geworteld in onze liefde voor Calpe, een stad gezegend met 300 dagen zon per jaar, wat het een ideale plek maakt voor de installatie van zonnepanelen.",
        paragraph3: "Wij bieden een scala aan diensten, waaronder duurzame installaties en projecten die op tijd worden voltooid, ontworpen om u geld te besparen terwijl u geniet van de voordelen van schone energie. Onze klantgerichte aanpak richt zich op zowel expats als lokale bewoners om te voldoen aan de unieke behoeften van onze diverse klantenkring.",
        viewProjects: "Bekijk onze projecten",
        askQuote: "Vraag een gratis offerte aan"
      } ,
      choose: {
        title: "Waarom voor ons kiezen",
        description: "Kiezen voor ons betekent dat je een betrouwbare en ervaren professional krijgt die de expertise heeft om kwaliteitsdiensten te leveren.",
        experienced_team: {
          name: "Ervaren en gekwalificeerd team",
          summary: "Ons team is hooggekwalificeerd en goed uitgerust om elk probleem aan te pakken waarmee je mogelijk te maken hebt"
        },
        reliable_service: {
          name: "Betrouwbare en efficiënte service",
          summary: "We streven ernaar om betrouwbare en efficiënte service te bieden aan onze klanten. We komen op tijd, werken snel en efficiënt"
        },
        competitive_pricing: {
          name: "Concurrerende prijzen",
          summary: "We bieden concurrerende prijzen zonder concessies te doen aan de kwaliteit van ons werk"
        },
        customer_satisfaction: {
          name: "Klanttevredenheid",
          summary: "We luisteren naar je behoeften en zorgen en werken met je samen om de beste oplossing te vinden"
        },
        fast_delivery: {
          name: "Snelle levering",
          summary: "We zijn snel met het leveren van offertes en installaties"
        }
      },
      navbar: {
        home: "START",
        about: "OVER ONS",
        services: "DIENSTEN",
        projects: "PROJECTEN",
        faqs: "FAQ'S",
        contact: "CONTACT",
        ask_for_quote: "Vraag een gratis offerte aan"
      },
      footer: {
        description: "Eco Green Projects is uw partner en expert in zonne-energie voor uw project in Zuid-Spanje. Onze missie is om u te helpen geld te besparen dankzij onze installaties.",
        our_services: "Onze Diensten",
        solar_panel_installation: "Zonnepaneel Installatie",
        air_conditioning_installation: "Oplossingen voor Airconditioninginstallatie",
        pool_heat_pump_installation: "Installatie van Warmtepomp voor Zwembad",
        electric_car_charging_station_installation: "Installatie van Laadstations voor Elektrische Auto's",
        contact: "Contact",
        follow_us: "Volg Ons",
        our_presence: "Onze Aanwezigheid in Zuid-Spanje",
        implementation_in_calpe: "Uitvoering in Calpe",
        implementation_in_altea: "Uitvoering in Altea",
        implementation_in_moraira: "Uitvoering in Moraira",
        implementation_in_benissa: "Uitvoering in Benissa",
        terms_conditions: "Algemene Voorwaarden",
        privacy_policy: "Privacybeleid"
      },
      aboutpage: {
        title: "Over ons",
        heading: "Bij Eco Green Projects zetten we ons in om de manier waarop u energie benut te transformeren.",
        founder_info: "Opgericht door Sebastien, die verliefd werd op het adembenemende landschap van Calpe, is onze missie om zowel de lokale bevolking als expats in staat te stellen een duurzaam leven te omarmen door middel van zonne-energieoplossingen.",
        savings: "Stel je voor dat je je elektriciteitsrekening met maar liefst 85% kunt verlagen! Onze zonnepaneelinstallaties zijn niet alleen een slimme keuze, maar ook een gamechanger voor je portemonnee en het milieu.",
        experience: "Al meer dan 15 jaar zijn we goed gevestigd in de zonnepaneelindustrie in België, waar we verschillende installaties met succes hebben voltooid. Vandaag brengen we onze expertise naar de zonnige kusten van Calpe, Altea, Moraira, Benissa en verder.",
        services_title: "Onze volledige reeks diensten omvat:",
        services: [
          "Zonnepaneelinstallatie: maximaliseer je energiebesparingen met onze geavanceerde zonne-energietechnologie.",
          "Airconditioningoplossingen: houd je huis koel en comfortabel tijdens de hete zomermaanden.",
          "Zwembadwarmtepompen: geniet het hele jaar door van je zwembad met energiezuinige verwarmingsoplossingen.",
          "Thermodynamische warmwaterboilers: benut hernieuwbare energie voor je warmwaterbehoeften.",
          "Elektrische laadstations: zorg ervoor dat je huis is uitgerust met de nieuwste elektrische oplossingen."
        ],
        closing: "Sluit je aan bij ons om een milieuvriendelijkere omgeving te creëren en tegelijkertijd geld te besparen. Laat Eco Green Projects je begeleiden op je weg naar duurzaam leven. Neem vandaag nog contact met ons op om te ontdekken hoe wij je kunnen helpen om over te schakelen naar zonne-energie en te profiteren van de voordelen van een groenere levensstijl!"
      },
      result: {
        title: "Onze Diensten",
        services: [
          {
            title: "Installatie van zonnepanelen",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden wij professionele installatiediensten voor zonnepanelen om u te helpen hernieuwbare energie te benutten en uw energierekeningen te verlagen.</strong></span></br><span style=display:block;> Onze gecertificeerde technici zorgen voor deskundige installatie met hoogwaardige zonnepanelen voor maximale efficiëntie en minimale verstoring van uw dagelijkse leven.</span></br><span style=display:block;> Sluit u aan bij de groene energierevolutie en kies vandaag nog voor duurzame en kosteneffectieve energieoplossingen.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Installatie van zonnepanelen"
          },
          {
            title: "Oplossingen voor airconditioninginstallatie",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden wij professionele installatiediensten voor airconditioning om optimaal comfort te garanderen voor uw huis of bedrijf.</strong></span></br><span style=display:block;> Ons gecertificeerd HVAC-team gebruikt hoogwaardige units en de nieuwste technologie om efficiënte en energiebesparende koeloplossingen te leveren die op uw behoeften zijn afgestemd.</span></br><span style=display:block;> Verbeter uw binnenomgeving en geniet het hele jaar door van een perfect gecontroleerd klimaat.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Oplossingen voor airconditioninginstallatie"
          },
          {
            title: "Warmtepompen voor zwembadverwarming",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden wij professionele oplossingen voor het installeren van warmtepompen voor zwembaden om uw zwemseizoen het hele jaar door te verlengen.</strong></span></br><span style=display:block;> Onze energiezuinige warmtepompen zijn afgestemd op de grootte en behoeften van uw zwembad, zodat optimale prestaties en energiebesparing worden gegarandeerd.</span></br><span style=display:block;> Verleng het gebruik van uw zwembad met milieuvriendelijke verwarmingsoplossingen die energiekosten verlagen.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Warmtepompen voor zwembadverwarming"
          },
          {
            title: "Installatie van warmtepompboilers",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden wij efficiënte en duurzame installaties van warmtepompboilers voor woningen en bedrijven.</strong></span></br><span style=display:block;> Onze gecertificeerde professionals maken gebruik van geavanceerde warmtepomptechnologieën om energiekosten te verlagen en uw ecologische voetafdruk te minimaliseren.</span></br><span style=display:block;> Geniet van een probleemloze installatie en een energiezuinig verbruik dat een groenere toekomst ondersteunt.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Installatie van warmtepompboilers"
          },
          {
            title: "Installatie van laadstations voor elektrische voertuigen",
            description: "<span style=display:block;><strong>Bij Eco Green Projects zijn wij gespecialiseerd in de installatie van laadstations voor elektrische voertuigen (EV) om duurzaam vervoer te ondersteunen.</strong></span></br><span style=display:block;> Ons team van experts zorgt voor efficiënte en betrouwbare installaties met hoogwaardige apparatuur, zodat optimale prestaties en minimale verstoring van uw dagelijkse leven worden gegarandeerd.</span></br><span style=display:block;> Stap over naar een groenere toekomst met onze EV-laadoplossingen die zijn afgestemd op uw huis of bedrijf.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Installatie van laadstations voor elektrische voertuigen"
          }
        ]
        
      },
      
      project: {
        title: "Onze Projecten",
        projectTitle: "ONS PROJECT IN CALPE",
        description1: "We zijn verheugd ons eerste project in Calpe te presenteren.",
        description2: "Dit project omvat een complete installatie van zonnepanelen en een omvormer, uitgerust met een batterijsysteem. Daarnaast hebben we airconditioning, een zwembadwarmtepomp, een thermodynamische boiler en een laadpaal voor elektrische voertuigen geïmplementeerd om de duurzaamheid en efficiëntie van het pand te verbeteren.",
        energyGraph: "Energieverbruik Grafiek",
        installationOverview: "Installatie Overzicht"
    },    
      
        "faqTitle": "FAQ: Zonne-energie en installatiediensten in Zuid-Spanje",
        "faq1": {
          "title": "Wat kost het installeren van zonnepanelen?",
          "content": "De kosten voor een installatie van zonnepanelen kunnen variëren afhankelijk van verschillende factoren, zoals de oriëntatie van uw woning, de beschikbare oppervlakte voor de panelen en uw energiebehoeften. Voor huiseigenaren in gebieden zoals Calpe, Altea en Benissa liggen de prijzen doorgaans tussen de €5.000 en €12.000 voor een complete installatie. U profiteert van aanzienlijke besparingen op uw energierekeningen op de lange termijn. Voor een nauwkeurige offerte op maat van uw woning en behoeften bieden wij gratis en vrijblijvende offertes aan."
        },
        "faq2": {
          "title": "Wat is het rendement (ROI) van zonnepanelen in Spanje?",
          "content": "Het rendement van zonnepanelen in Spanje is uitstekend, vooral langs de Costa Blanca, waar het weer 300 zonnige dagen per jaar biedt. De meeste huiseigenaren zien een terugverdientijd van 4 tot 5 jaar, afhankelijk van de grootte van het systeem en het energieverbruik. Met de toevoeging van batterijen kunnen de besparingen nog groter zijn door de verminderde afhankelijkheid van het elektriciteitsnet. Zonne-energie kan uw elektriciteitsrekeningen tot wel 85% verlagen, wat het een winstgevende investering op de lange termijn maakt."
        },
        "faq3": {
          "title": "Waarom kiezen voor Eco Green Projects voor de installatie van zonnepanelen?",
          "content": "Met 15 jaar ervaring in België hebben we een reputatie opgebouwd voor kwaliteit en betrouwbaarheid. We brengen hetzelfde niveau van expertise naar Zuid-Spanje, met diensten in gebieden zoals Calpe, Altea, Benissa, Moraira en verder. Onze belangrijkste voordelen zijn:<ul><li>Snelle reacties: Wij leveren snel offertes, zodat u niet hoeft te wachten.</li><li>Efficiënte installatie: We voeren installaties sneller uit dan de meeste concurrenten, met minimale verstoring.</li><li>Meertalige ondersteuning: Ons team communiceert in Engels, Spaans, Frans en Nederlands, wat de communicatie voor expats vergemakkelijkt.</li></ul>Wij zijn uw betrouwbare partner voor zonne-energieoplossingen aan de Costa Blanca."
        },
        "faq4": {
          "title": "Hoe lang duurt het om zonnepanelen te installeren?",
          "content": "Wij streven ernaar om zonnepaneelinstallaties snel te voltooien. Over het algemeen duurt het proces tussen de 1 en 3 dagen, afhankelijk van de grootte van het systeem en de complexiteit van de installatie. Zodra u een offerte heeft ontvangen en besluit door te gaan, kunnen we de installatie meestal snel plannen voor woningen in Calpe, Altea, Moraira, Benissa en omliggende gebieden. Ons doel is om het proces zo snel en probleemloos mogelijk te maken."
        },
        "faq5": {
          "title": "Hoe kan ik een offerte krijgen voor de installatie van zonnepanelen?",
          "content": "Het verkrijgen van een gratis offerte voor de installatie van zonnepanelen is eenvoudig. U kunt:<ul><li>Ons bellen of e-mailen, en ons team zal een consultatie organiseren.</li><li>Het formulier op onze website invullen, en we nemen binnen 24 uur contact met u op.</li></ul>We bieden persoonlijke offertes op basis van uw energiebehoeften en de specificaties van uw woning, zodat u een nauwkeurige en concurrerende prijs ontvangt."
        },
        "faq6": {
          "title": "Welke soorten installaties bieden jullie aan?",
          "content": "Wij zijn gespecialiseerd in een breed scala aan energieoplossingen, waaronder:<ul><li>Zonnepaneelinstallatie: Efficiënte en kosteneffectieve systemen die op uw huis zijn afgestemd.</li><li>Installatie van airconditioning: Energiezuinige units die uw huis het hele jaar door comfortabel houden.</li><li>Warmtepompinstallatie voor zwembaden: Verleng uw zwemseizoen met milieuvriendelijke verwarmingssystemen.</li><li>Installatie van thermodynamische boilers: Duurzame voorbereiding van warm water voor uw huis.</li><li>Installatie van laadstations voor elektrische voertuigen: Handige laadoplossingen thuis voor uw EV.</li></ul>Elke dienst is beschikbaar in Calpe, Altea, Moraira, Benissa en omliggende regio's."
        },
        "faq7": {
          "title": "Welke gebieden bedienen jullie?",
          "content": "Wij bedienen met trots huiseigenaren en expats in heel de Costa Blanca, waaronder:<ul><li>Calpe</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Of u nu op zoek bent naar zonne-energieoplossingen, airconditioninginstallaties of laadstations voor elektrische voertuigen, wij leveren hoogwaardige en betrouwbare diensten in de hele regio."
        },      
      contactButtonText: "Uw offerte in een paar klikken!"

    }
  },
  al: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projects",
        goGreenSaveGreen: "Shkoni Green dhe Kurseni Green me Energi Solare",
        contactUs: "NA KONTAKTONI",
        ourServices: "SHERBIMET TONA"
      },
      about: {
        aboutUsTitle: "Über Uns",
        aboutUsText1: "Eco Green Projects, gegründet von Sébastien, der sich in die atemberaubenden Landschaften von Calpe verliebt hat, hat die Mission, Einheimischen und Expats zu ermöglichen, einen nachhaltigen Lebensstil durch Solarenergie-Lösungen zu übernehmen.",
        aboutUsText2: "Stellen Sie sich vor, Ihre Stromrechnung um 85% zu senken! Unsere Solaranlagen sind nicht nur eine kluge Wahl, sondern eine echte Veränderung für Ihren Geldbeutel und die Umwelt.",
        aboutUsText3: "Mit über 15 Jahren Erfahrung in der Solarindustrie in Belgien, wo wir zahlreiche Installationen abgeschlossen haben, bringen wir unser Fachwissen nun an die sonnigen Küsten von Calpe, Altea, Moraira, Benissa und darüber hinaus.",
        quoteButton: "Fordern Sie ein kostenloses Angebot an"
      },      
        services: {
          ourServicesTitle: "Shërbimet tona",
          solarPanelInstallation: "Instalimi i Paneleve Solare",
          airConditioningInstallation: "Instalimi i Klimatizimit",
          poolHeatPumpInstallation: "Instalimi i pompës së ngrohjes për pishinë",
          heatPumpBoilerInstallation: "Installation eines thermodynamischen Boilers",
          electricCarChargingStation: "Ladestation für Elektrofahrzeuge",
          serviceDetailButton: "Shërbimet tona në detaje",
          freeQuoteButton: "Kërkoni një ofertë falas",
          friendlyPersonalService: "Shërbim miqësor dhe personal",
          installationExperience: "Eksperiencë e pasur në Instalime dhe Mirëmbajtje",
          available: "Disponueshëm 24/7"
        },
        ourmission: {
          title: "Unsere Mission",
          paragraph1: "Bei Eco Green Projects setzen wir uns dafür ein, die Art und Weise, wie Sie Energie wahrnehmen, zu verändern. Mit einer Mission, die auf Kosteneinsparungen durch Solarenergie und der Förderung von Umweltbewusstsein basiert, bemühen wir uns, nachhaltiges Leben für alle zugänglich zu machen. Unsere Kernwerte – Innovation, intelligente Lösungen und Nachhaltigkeit – leiten uns bei jedem Projekt, das wir durchführen.",
          paragraph2: "Mit über 15 Jahren Erfahrung im Bereich Solarenergie und zahlreichen erfolgreichen Installationen haben wir uns als vertrauenswürdiger Marktführer in diesem Sektor etabliert. Unsere Leidenschaft für erneuerbare Energien ist tief in unserer Liebe zu Calpe verwurzelt, einer Stadt mit 300 Sonnentagen im Jahr, was sie zu einem idealen Ort für die Installation von Solaranlagen macht.",
          paragraph3: "Wir bieten eine Vielzahl von Dienstleistungen an, darunter nachhaltige Installationen und fristgerecht abgeschlossene Projekte, die darauf ausgelegt sind, Ihnen Geld zu sparen und gleichzeitig die Vorteile sauberer Energie zu genießen. Unser kundenorientierter Ansatz richtet sich sowohl an Expats als auch an Einheimische, um die einzigartigen Bedürfnisse unserer vielfältigen Kundschaft zu erfüllen.",
          viewProjects: "Unsere Projekte ansehen",
          askQuote: "Ein kostenloses Angebot anfordern"
        },        
        choose: {
          title: "Warum uns wählen",
          description: "Die Wahl für uns bedeutet, dass Sie einen zuverlässigen und erfahrenen Fachmann erhalten, der die Expertise hat, qualitativ hochwertige Dienstleistungen zu liefern.",
          experienced_team: {
            name: "Erfahrenes und qualifiziertes Team",
            summary: "Unser Team ist hochqualifiziert und gut ausgestattet, um jedes Problem zu lösen, mit dem Sie konfrontiert sein könnten"
          },
          reliable_service: {
            name: "Zuverlässiger und effizienter Service",
            summary: "Wir bemühen uns, unseren Kunden einen zuverlässigen und effizienten Service zu bieten. Wir kommen pünktlich, erledigen die Arbeit schnell und effizient"
          },
          competitive_pricing: {
            name: "Wettbewerbsfähige Preise",
            summary: "Wir bieten wettbewerbsfähige Preise, ohne die Qualität unserer Arbeit zu beeinträchtigen"
          },
          customer_satisfaction: {
            name: "Kundenzufriedenheit",
            summary: "Wir hören auf Ihre Bedürfnisse und Anliegen und arbeiten mit Ihnen zusammen, um die beste Lösung zu finden"
          },
          fast_delivery: {
            name: "Schnelle Lieferung",
            summary: "Wir sind schnell bei der Lieferung von Angeboten und Installationen"
          }
        },
        navbar: {
          home: "STARTSEITE",
          about: "ÜBER UNS",
          services: "DIENSTLEISTUNGEN",
          projects: "PROJEKTE",
          faqs: "FAQ",
          contact: "KONTAKT",
          ask_for_quote: "Kostenloses Angebot anfordern"
        },
        footer: {
          description: "Eco Green Projects ist Ihr Partner und Experte für Solarenergie für Ihr Projekt im Süden Spaniens. Unsere Mission ist es, Ihnen durch unsere Installationen Geld zu sparen.",
          our_services: "Unsere Dienstleistungen",
          solar_panel_installation: "Installation von Solarmodulen",
          air_conditioning_installation: "Lösungen zur Klimaanlageninstallation",
          pool_heat_pump_installation: "Installation einer Pool-Wärmepumpe",
          electric_car_charging_station_installation: "Installation von Ladestationen für Elektroautos",
          contact: "Kontakt",
          follow_us: "Folgen Sie uns",
          our_presence: "Unsere Präsenz im Süden Spaniens",
          implementation_in_calpe: "Umsetzung in Calpe",
          implementation_in_altea: "Umsetzung in Altea",
          implementation_in_moraira: "Umsetzung in Moraira",
          implementation_in_benissa: "Umsetzung in Benissa",
          terms_conditions: "Allgemeine Geschäftsbedingungen",
          privacy_policy: "Datenschutzrichtlinie"
        },
        aboutpage: {
          title: "Über uns",
          heading: "Bei Eco Green Projects setzen wir uns dafür ein, die Art und Weise zu verändern, wie Sie Energie nutzen.",
          founder_info: "Gegründet von Sebastien, der sich in die atemberaubenden Landschaften von Calpe verliebte, ist es unsere Mission, Einheimische und Expats zu befähigen, durch Solarlösungen ein nachhaltiges Leben zu führen.",
          savings: "Stellen Sie sich vor, Sie könnten Ihre Stromrechnung um unglaubliche 85 % senken! Unsere Solaranlagen sind nicht nur eine clevere Wahl, sondern ein echter Game-Changer für Ihr Portemonnaie und die Umwelt.",
          experience: "Seit über 15 Jahren sind wir in der Solarbranche in Belgien gut etabliert und haben erfolgreich viele Installationen abgeschlossen. Heute bringen wir unsere Expertise an die sonnigen Küsten von Calpe, Altea, Moraira, Benissa und darüber hinaus.",
          services_title: "Unser vollständiges Leistungsspektrum umfasst:",
          services: [
            "Solaranlageninstallation: Maximieren Sie Ihre Energieeinsparungen mit unserer modernen Solartechnologie.",
            "Klimaanlagenlösungen: Halten Sie Ihr Zuhause während der heißen Sommermonate kühl und komfortabel.",
            "Wärmepumpen für Pools: Genießen Sie Ihren Pool das ganze Jahr über mit energieeffizienten Heizlösungen.",
            "Thermodynamische Warmwasserboiler: Nutzen Sie erneuerbare Energien für Ihre Warmwasserbedürfnisse.",
            "Elektrische Ladestationen: Stellen Sie sicher, dass Ihr Zuhause mit den neuesten elektrischen Lösungen ausgestattet ist."
          ],
          closing: "Schließen Sie sich uns an, um ein umweltfreundliches Umfeld zu schaffen und gleichzeitig Geld zu sparen. Lassen Sie Eco Green Projects Sie auf Ihrem Weg zu einem nachhaltigen Leben begleiten. Kontaktieren Sie uns noch heute, um herauszufinden, wie wir Ihnen helfen können, auf Solarenergie umzusteigen und die Vorteile eines grüneren Lebensstils zu genießen!"
        },
        result: {
          title: "Unsere Dienstleistungen",
          services: [
            {
              title: "Installation von Solaranlagen",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir professionelle Dienstleistungen zur Installation von Solaranlagen, um Ihnen zu helfen, erneuerbare Energien zu nutzen und Ihre Energiekosten zu senken.</strong></span></br><span style=display:block;> Unsere zertifizierten Techniker sorgen für eine fachmännische Installation mit hochwertigen Solarmodulen, um maximale Effizienz und minimale Beeinträchtigung Ihres Alltags zu gewährleisten.</span></br><span style=display:block;> Werden Sie Teil der grünen Energiewende und entscheiden Sie sich noch heute für nachhaltige und kostengünstige Energielösungen.</span>",
              button: "Kostenloses Angebot anfordern",
              altText: "Installation von Solaranlagen"
            },
            {
              title: "Lösungen für Klimaanlageninstallation",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir professionelle Dienstleistungen zur Installation von Klimaanlagen, um optimalen Komfort für Ihr Zuhause oder Ihr Unternehmen zu gewährleisten.</strong></span></br><span style=display:block;> Unser zertifiziertes HVAC-Team verwendet hochwertige Geräte und die neueste Technologie, um effiziente und energiesparende Kühlungslösungen anzubieten, die auf Ihre Bedürfnisse zugeschnitten sind.</span></br><span style=display:block;> Verbessern Sie Ihre Innenraumumgebung und genießen Sie das ganze Jahr über ein perfekt kontrolliertes Klima.</span>",
              button: "Kostenloses Angebot anfordern",
              altText: "Lösungen für Klimaanlageninstallation"
            },
            {
              title: "Installation von Pool-Wärmepumpen",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir professionelle Lösungen zur Installation von Pool-Wärmepumpen, um Ihre Badesaison das ganze Jahr über zu verlängern.</strong></span></br><span style=display:block;> Unsere energieeffizienten Wärmepumpen sind auf die Größe und den Bedarf Ihres Pools abgestimmt und gewährleisten optimale Leistung und Energieeinsparungen.</span></br><span style=display:block;> Verlängern Sie die Nutzung Ihres Pools mit umweltfreundlichen Heizlösungen, die Ihre Energiekosten senken.</span>",
              button: "Kostenloses Angebot anfordern",
              altText: "Installation von Pool-Wärmepumpen"
            },
            {
              title: "Installation von Wärmepumpen-Wasserboilern",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir effiziente und langlebige Installationen von Wärmepumpen-Wasserboilern für Wohnhäuser und Unternehmen.</strong></span></br><span style=display:block;> Unsere zertifizierten Fachleute nutzen modernste Wärmepumpentechnologien, um Energiekosten zu senken und Ihren CO2-Fußabdruck zu minimieren.</span></br><span style=display:block;> Profitieren Sie von einer mühelosen Installation und einem energieeffizienten Verbrauch, der eine grünere Zukunft unterstützt.</span>",
              button: "Kostenloses Angebot anfordern",
              altText: "Installation von Wärmepumpen-Wasserboilern"
            },
            {
              title: "Installation von Ladestationen für Elektrofahrzeuge",
              description: "<span style=display:block;><strong>Bei Eco Green Projects sind wir auf die Installation von Ladestationen für Elektrofahrzeuge (EV) spezialisiert, um nachhaltige Mobilität zu unterstützen.</strong></span></br><span style=display:block;> Unser Expertenteam sorgt für effiziente und zuverlässige Installationen mit hochwertiger Ausrüstung, um optimale Leistung und minimale Beeinträchtigungen Ihres Alltags zu gewährleisten.</span></br><span style=display:block;> Wechseln Sie zu einer grüneren Zukunft mit unseren EV-Ladelösungen, die auf Ihr Zuhause oder Unternehmen zugeschnitten sind.</span>",
              button: "Kostenloses Angebot anfordern",
              altText: "Installation von Ladestationen für Elektrofahrzeuge"
            }
          ]
          
        },
        
        project: {
          title: "Unsere Projekte",
          projectTitle: "UNSER PROJEKT IN CALPE",
          description1: "Wir freuen uns, Ihnen unser erstes Projekt in Calpe vorzustellen.",
          description2: "Dieses Projekt umfasst eine vollständige Installation von Solarmodulen und einem Wechselrichter, ausgestattet mit einem Batteriesystem. Zusätzlich haben wir eine Klimaanlage, eine Pool-Wärmepumpe, einen thermodynamischen Wasserboiler und eine Ladestation für Elektrofahrzeuge installiert, um die Nachhaltigkeit und Effizienz der Immobilie zu verbessern.",
          energyGraph: "Energieverbrauchsdiagramm",
          installationOverview: "Installationsübersicht"
      },      
        
          "faqTitle": "FAQ: Dienstleistungen und Installation von Solaranlagen in Südspanien",
          "faq1": {
            "title": "Wie viel kostet die Installation von Solarmodulen?",
            "content": "Die Kosten für die Installation von Solarmodulen variieren je nach Faktoren wie der Ausrichtung Ihres Hauses, der verfügbaren Fläche für die Module und Ihrem Energiebedarf. In Gebieten wie Calpe, Altea und Benissa liegen die Preise für eine vollständige Installation in der Regel zwischen 5.000 € und 12.000 €. Langfristig profitieren Sie von erheblichen Einsparungen bei Ihren Energiekosten. Für ein genaues und individuell auf Ihr Zuhause zugeschnittenes Angebot bieten wir kostenlose und unverbindliche Kostenvoranschläge an."
          },
          "faq2": {
            "title": "Wie hoch ist der ROI (Return on Investment) von Solarmodulen in Spanien?",
            "content": "Der ROI von Solarmodulen in Spanien ist hervorragend, besonders an der Costa Blanca, wo es 300 Sonnentage im Jahr gibt. Die meisten Hausbesitzer sehen eine Amortisation innerhalb von 4 bis 5 Jahren, abhängig von der Größe der Anlage und dem Energieverbrauch. Mit zusätzlichen Batterien können die Einsparungen noch größer ausfallen, da die Abhängigkeit vom Stromnetz verringert wird. Solarmodule können Ihre Stromkosten um bis zu 85 % senken, was sie zu einer langfristig rentablen Investition macht."
          },
          "faq3": {
            "title": "Warum sollte ich Eco Green Projects für die Installation von Solarmodulen wählen?",
            "content": "Mit 15 Jahren Erfahrung in Belgien haben wir uns einen Ruf für Qualität und Zuverlässigkeit erarbeitet. Wir bringen dieses Fachwissen nach Südspanien und bedienen Gebiete wie Calpe, Altea, Benissa, Moraira und darüber hinaus. Unsere Vorteile umfassen:<ul><li>Schnelle Reaktionszeiten: Wir liefern Angebote zügig, sodass Sie nicht lange warten müssen.</li><li>Effiziente Installation: Wir führen Installationen schneller durch als die meisten Mitbewerber und minimieren dabei Störungen.</li><li>Mehrsprachiger Support: Unser Team spricht Englisch, Spanisch, Französisch und Niederländisch, was die Kommunikation für Expats erleichtert.</li></ul>Wir sind Ihr vertrauenswürdiger Partner für Solarenergie-Lösungen an der Costa Blanca."
          },
          "faq4": {
            "title": "Wie lange dauert die Installation von Solarmodulen?",
            "content": "Wir bemühen uns, die Installation von Solarmodulen schnell abzuschließen. In der Regel dauert der Prozess zwischen 1 und 3 Tagen, abhängig von der Größe der Anlage und der Komplexität der Installation. Sobald Sie ein Angebot erhalten haben und sich für eine Umsetzung entscheiden, können wir die Installation in der Regel schnell für Immobilien in Calpe, Altea, Moraira, Benissa und Umgebung planen. Unser Ziel ist es, den Prozess so schnell und unkompliziert wie möglich zu gestalten."
          },
          "faq5": {
            "title": "Wie kann ich ein Angebot für die Installation von Solarmodulen erhalten?",
            "content": "Ein kostenloses Angebot für die Installation von Solarmodulen zu erhalten, ist ganz einfach. Sie können:<ul><li>Uns anrufen oder eine E-Mail senden, und unser Team organisiert eine Beratung.</li><li>Das Formular auf unserer Website ausfüllen, und wir melden uns innerhalb von 24 Stunden bei Ihnen.</li></ul>Wir bieten individuelle Angebote, die auf Ihren Energiebedarf und die Eigenschaften Ihrer Immobilie abgestimmt sind, sodass Sie einen genauen und wettbewerbsfähigen Preis erhalten."
          },
          "faq6": {
            "title": "Welche Installationen bieten Sie an?",
            "content": "Wir sind spezialisiert auf eine Vielzahl von Energielösungen, darunter:<ul><li>Installation von Solarmodulen: Effiziente und kostengünstige Systeme, die auf Ihr Zuhause zugeschnitten sind.</li><li>Installation von Klimaanlagen: Energiesparende Geräte, die Ihr Zuhause das ganze Jahr über angenehm halten.</li><li>Installation von Wärmepumpen für Schwimmbäder: Verlängern Sie Ihre Schwimmsaison mit umweltfreundlichen Heizsystemen.</li><li>Installation von thermodynamischen Wasserboilern: Nachhaltige Warmwasserbereitung für Ihr Zuhause.</li><li>Installation von Ladestationen für Elektrofahrzeuge: Praktische Ladelösungen für Ihr Elektrofahrzeug zu Hause.</li></ul>Jeder Service ist in Calpe, Altea, Moraira, Benissa und den umliegenden Regionen verfügbar."
          },
          "faq7": {
            "title": "Welche Regionen bedienen Sie?",
            "content": "Wir bedienen stolz Hausbesitzer und Expats in der gesamten Costa Blanca, einschließlich:<ul><li>Calpe</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Ob Sie Solarenergie-Lösungen, Klimaanlagen-Installationen oder Ladestationen für Elektrofahrzeuge benötigen, wir bieten hochwertige und zuverlässige Dienstleistungen in der gesamten Region."
          },        
  contactButtonText: "Ihr Angebot in wenigen Klicks!"
      
    }
  },
  es: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projects",
        goGreenSaveGreen: "Ve verde y ahorra verde con energía solar",
        contactUs: "CONTACTANOS",
        ourServices: "NUESTROS SERVICIOS"
      },
      about: {
        aboutUsTitle: "Sobre Nosotros",
        aboutUsText1: "Eco Green Projects, fundado por Sébastien, quien se enamoró de los impresionantes paisajes de Calpe, tiene como misión permitir a los locales y expatriados adoptar un estilo de vida sostenible a través de soluciones solares.",
        aboutUsText2: "¡Imagina reducir tu factura de electricidad en un 85%! Nuestras instalaciones de paneles solares no solo son una elección inteligente, sino un verdadero cambio para tu bolsillo y el medio ambiente.",
        aboutUsText3: "Con más de 15 años de experiencia en la industria de paneles solares en Bélgica, donde hemos realizado numerosas instalaciones, ahora traemos nuestra experiencia a las soleadas costas de Calpe, Altea, Moraira, Benissa y más allá.",
        quoteButton: "Solicita un presupuesto gratuito"
      },      
      services: {
        ourServicesTitle: "Nuestros Servicios",
        solarPanelInstallation: "Instalación de Paneles Solares",
        airConditioningInstallation: "Instalación de Aire Acondicionado",
        poolHeatPumpInstallation: "Instalación de Bomba de Calor para Piscinas",
        heatPumpBoilerInstallation: "Instalación de termo de agua termodinámico",
        electricCarChargingStation: "Cargador para vehículos eléctricos",
        serviceDetailButton: "Nuestros servicios en detalle",
        freeQuoteButton: "Solicitar una cotización gratuita",
        friendlyPersonalService: "Servicio Amigable y Personalizado",
        installationExperience: "Gran Experiencia en Instalación y Mantenimiento",
        available: "Disponible las 24 horas, los 7 días de la semana"
      },
      ourmission: {
        title: "Nuestra Misión",
        paragraph1: "En Eco Green Projects, estamos comprometidos a transformar la forma en que percibes la energía. Con una misión centrada en el ahorro económico a través de la energía solar mientras promovemos la eco-responsabilidad, nos esforzamos por hacer que la vida sostenible sea accesible para todos. Nuestros valores fundamentales de innovación, soluciones inteligentes y sostenibilidad nos guían en cada proyecto que emprendemos.",
        paragraph2: "Con más de 15 años de experiencia en el campo de la energía solar y numerosas instalaciones exitosas, nos hemos establecido como un líder confiable en este sector. Nuestra pasión por las energías renovables está profundamente arraigada en nuestro amor por Calpe, una ciudad bendecida con 300 días de sol al año, lo que la convierte en un lugar ideal para la instalación de paneles solares.",
        paragraph3: "Ofrecemos una gama de servicios, incluyendo instalaciones sostenibles y proyectos finalizados a tiempo, diseñados para ayudarte a ahorrar dinero mientras disfrutas de los beneficios de la energía limpia. Nuestro enfoque centrado en el cliente atiende tanto a expatriados como a residentes locales para satisfacer las necesidades únicas de nuestra diversa clientela.",
        viewProjects: "Ver nuestros proyectos",
        askQuote: "Solicitar un presupuesto gratuito"
      },      
      choose: {
        title: "Por qué elegirnos",
        description: "Elegirnos significa que obtendrás un profesional confiable y experimentado con la experiencia necesaria para ofrecer servicios de calidad.",
        experienced_team: {
          name: "Equipo experimentado y capacitado",
          summary: "Nuestro equipo está altamente experimentado y capacitado, listo para manejar cualquier problema que puedas enfrentar"
        },
        reliable_service: {
          name: "Servicio confiable y eficiente",
          summary: "Nos esforzamos por proporcionar un servicio confiable y eficiente a nuestros clientes. Llegamos a tiempo, completamos el trabajo rápidamente y de manera eficiente"
        },
        competitive_pricing: {
          name: "Precios competitivos",
          summary: "Ofrecemos precios competitivos sin comprometer la calidad de nuestro trabajo"
        },
        customer_satisfaction: {
          name: "Satisfacción del cliente",
          summary: "Escuchamos tus necesidades y preocupaciones, y trabajamos contigo para encontrar la mejor solución"
        },
        fast_delivery: {
          name: "Entrega rápida",
          summary: "Somos rápidos en entregar presupuestos e instalaciones"
        }
      },
      navbar: {
        home: "INICIO",
        about: "SOBRE NOSOTROS",
        services: "SERVICIOS",
        projects: "PROYECTOS",
        faqs: "FAQ",
        contact: "CONTACTO",
        ask_for_quote: "Solicitar una cotización gratuita"
      },
      footer: {
        description: "Eco Green Projects es su socio y experto en energía solar para su proyecto en el sur de España. Nuestra misión es ayudarle a ahorrar dinero gracias a nuestras instalaciones.",
    our_services: "Nuestros Servicios",
    solar_panel_installation: "Instalación de Paneles Solares",
    air_conditioning_installation: "Soluciones de Instalación de Aire Acondicionado",
    pool_heat_pump_installation: "Instalación de Bomba de Calor para Piscinas",
    electric_car_charging_station_installation: "Instalación de Estaciones de Carga para Coches Eléctricos",
    contact: "Contacto",
    follow_us: "Síguenos",
    our_presence: "Nuestra Presencia en el Sur de España",
    implementation_in_calpe: "Implementación en Calpe",
    implementation_in_altea: "Implementación en Altea",
    implementation_in_moraira: "Implementación en Moraira",
    implementation_in_benissa: "Implementación en Benissa",
    terms_conditions: "Términos y Condiciones",
    privacy_policy: "Política de Privacidad"
      },
      aboutpage: {
        title: "Sobre nosotros",
        heading: "En Eco Green Projects, estamos comprometidos a transformar la manera en que aprovechas la energía.",
        founder_info: "Fundado por Sebastien, quien se enamoró de los impresionantes paisajes de Calpe, nuestra misión es empoderar a los lugareños y expatriados para adoptar un estilo de vida sostenible a través de soluciones de energía solar.",
        savings: "¡Imagina reducir tu factura de electricidad en un increíble 85%! Nuestras instalaciones de paneles solares no solo son una opción inteligente, ¡son un cambio total para tu bolsillo y el medio ambiente!",
        experience: "Durante más de 15 años, hemos estado bien establecidos en la industria de paneles solares en Bélgica, habiendo completado con éxito varias instalaciones. Hoy, llevamos nuestra experiencia a las soleadas costas de Calpe, Altea, Moraira, Benissa y más allá.",
        services_title: "Nuestros servicios incluyen:",
        services: [
          "Instalación de paneles solares: maximiza tus ahorros energéticos con nuestra tecnología solar de última generación.",
          "Soluciones de aire acondicionado: mantén tu hogar fresco y cómodo durante los calurosos meses de verano.",
          "Bombas de calor para piscinas: disfruta de tu piscina todo el año con soluciones de calefacción eficientes en energía.",
          "Tanques de agua caliente termodinámicos: aprovecha la energía renovable para tus necesidades de agua caliente.",
          "Terminales eléctricos: asegúrate de que tu hogar esté equipado con las últimas soluciones eléctricas."
        ],
        closing: "¡Únete a nosotros en nuestro compromiso de crear un ambiente ecológico mientras ahorras dinero! Deja que Eco Green Projects te guíe en tu camino hacia un estilo de vida sostenible. ¡Contáctanos hoy mismo para descubrir cómo podemos ayudarte a cambiar a la energía solar y disfrutar de los beneficios de un estilo de vida más verde!"
      },
      result: {
        title: "Nuestros Servicios",
        services: [
          {
            title: "Instalación de paneles solares",
            description: "<span style=display:block;><strong>En Eco Green Projects, ofrecemos servicios profesionales de instalación de paneles solares para ayudarte a aprovechar la energía renovable y reducir tus facturas de energía.</strong></span></br><span style=display:block;> Nuestros técnicos certificados garantizan una instalación experta utilizando paneles solares de alta calidad para maximizar la eficiencia y minimizar las interrupciones en tu vida diaria.</span></br><span style=display:block;> Únete a la revolución de la energía verde y elige soluciones energéticas sostenibles y rentables hoy mismo.</span>",
            button: "Solicitar un presupuesto gratuito",
            altText: "Instalación de paneles solares"
          },
          {
            title: "Soluciones de instalación de aire acondicionado",
            description: "<span style=display:block;><strong>En Eco Green Projects, ofrecemos servicios profesionales de instalación de aire acondicionado para garantizar un confort óptimo en tu hogar o negocio.</strong></span></br><span style=display:block;> Nuestro equipo certificado HVAC utiliza unidades de alta calidad y las tecnologías más recientes para ofrecer soluciones de enfriamiento eficientes y de bajo consumo energético, adaptadas a tus necesidades.</span></br><span style=display:block;> Mejora tu ambiente interior y disfruta de un clima perfectamente controlado durante todo el año.</span>",
            button: "Solicitar un presupuesto gratuito",
            altText: "Soluciones de instalación de aire acondicionado"
          },
          {
            title: "Instalación de bombas de calor para piscinas",
            description: "<span style=display:block;><strong>En Eco Green Projects, ofrecemos soluciones profesionales de instalación de bombas de calor para piscinas para ayudarte a extender la temporada de baño durante todo el año.</strong></span></br><span style=display:block;> Nuestras bombas de calor de bajo consumo están adaptadas al tamaño y las necesidades de tu piscina, garantizando un rendimiento óptimo y ahorros energéticos.</span></br><span style=display:block;> Extiende el uso de tu piscina con soluciones de calefacción ecológicas que reducen los costos energéticos.</span>",
            button: "Solicitar un presupuesto gratuito",
            altText: "Instalación de bombas de calor para piscinas"
          },
          {
            title: "Instalación de calentadores de agua con bomba de calor",
            description: "<span style=display:block;><strong>En Eco Green Projects, ofrecemos instalaciones de calentadores de agua con bomba de calor eficientes y duraderas para hogares y negocios.</strong></span></br><span style=display:block;> Nuestros profesionales certificados utilizan tecnologías avanzadas de bombas de calor para reducir los costos energéticos y minimizar tu huella de carbono.</span></br><span style=display:block;> Disfruta de una instalación sin complicaciones y adopta un consumo eficiente que apoye un futuro más verde.</span>",
            button: "Solicitar un presupuesto gratuito",
            altText: "Instalación de calentadores de agua con bomba de calor"
          },
          {
            title: "Instalación de estaciones de carga para vehículos eléctricos",
            description: "<span style=display:block;><strong>En Eco Green Projects, estamos especializados en la instalación de estaciones de carga para vehículos eléctricos (EV) para apoyar el transporte sostenible.</strong></span></br><span style=display:block;> Nuestro equipo de expertos garantiza instalaciones eficientes y confiables utilizando equipos de alta calidad, asegurando un rendimiento óptimo con una mínima interrupción de tu vida diaria.</span></br><span style=display:block;> Transición a un futuro más verde con nuestras soluciones de carga para vehículos eléctricos, adaptadas a tu hogar o negocio.</span>",
            button: "Solicitar un presupuesto gratuito",
            altText: "Instalación de estaciones de carga para vehículos eléctricos"
          }
        ]
        
      },      
      project: {
        title: "Nuestros Proyectos",
        projectTitle: "NUESTRO PROYECTO EN CALPE",
        description1: "Nos complace presentar nuestro proyecto inaugural en Calpe.",
        description2: "Este proyecto incluye una instalación completa de paneles solares e inversor, equipada con un sistema de baterías. Además, hemos implementado aire acondicionado, una bomba de calor para la piscina, un termo de agua termodinámico y un cargador para vehículos eléctricos para mejorar la sostenibilidad y eficiencia de la propiedad.",
        energyGraph: "Gráfico de Consumo Energético",
        installationOverview: "Resumen de la Instalación"
    },    
      
        "faqTitle": "FAQ: Servicios de energía solar e instalaciones en el sur de España",
        "faq1": {
          "title": "¿Cuánto cuesta instalar paneles solares?",
          "content": "El costo de instalar paneles solares puede variar según varios factores, como la orientación de su vivienda, la superficie disponible para los paneles y sus necesidades energéticas. Para propietarios en zonas como Calpe, Altea y Benissa, los precios suelen oscilar entre 5.000 € y 12.000 € por una instalación completa. A largo plazo, obtendrá ahorros significativos en sus facturas de energía. Ofrecemos presupuestos gratuitos y sin compromiso adaptados a las necesidades de su hogar."
        },
        "faq2": {
          "title": "¿Cuál es el ROI (Retorno de Inversión) de los paneles solares en España?",
          "content": "El ROI de los paneles solares en España es excelente, especialmente en la Costa Blanca, donde el clima ofrece 300 días de sol al año. La mayoría de los propietarios recuperan su inversión en 4 o 5 años, dependiendo del tamaño del sistema y el consumo energético. Con la adición de baterías, los ahorros pueden ser aún mayores debido a la menor dependencia de la red eléctrica. La energía solar puede reducir sus facturas de electricidad hasta en un 85%, lo que la convierte en una inversión rentable a largo plazo."
        },
        "faq3": {
          "title": "¿Por qué elegir Eco Green Projects para instalar paneles solares?",
          "content": "Con 15 años de experiencia en Bélgica, hemos construido una reputación de calidad y fiabilidad. Llevamos el mismo nivel de experiencia al sur de España, atendiendo áreas como Calpe, Altea, Benissa, Moraira y más allá. Nuestros principales beneficios incluyen:<ul><li>Respuestas rápidas: Ofrecemos presupuestos rápidamente, para que no tenga que esperar.</li><li>Instalación eficiente: Realizamos instalaciones más rápido que la mayoría de los competidores, minimizando las molestias.</li><li>Soporte multilingüe: Nuestro equipo habla inglés, español, francés y neerlandés, facilitando la comunicación para expatriados.</li></ul>Somos su socio confiable para soluciones de energía solar en la Costa Blanca."
        },
        "faq4": {
          "title": "¿Cuánto tiempo lleva instalar paneles solares?",
          "content": "Nos esforzamos por completar la instalación de paneles solares rápidamente. Por lo general, el proceso tarda entre 1 y 3 días, dependiendo del tamaño del sistema y la complejidad de la instalación. Una vez que haya recibido un presupuesto y decida proceder, normalmente podemos programar la instalación rápidamente para propiedades en Calpe, Altea, Moraira, Benissa y áreas cercanas. Nuestro objetivo es hacer que el proceso sea lo más rápido y sin complicaciones posible."
        },
        "faq5": {
          "title": "¿Cómo puedo obtener un presupuesto para instalar paneles solares?",
          "content": "Es fácil obtener un presupuesto gratuito para la instalación de paneles solares. Puede:<ul><li>Llamarnos o enviarnos un correo electrónico, y nuestro equipo organizará una consulta.</li><li>Completar el formulario en nuestro sitio web, y le responderemos en un plazo de 24 horas.</li></ul>Ofrecemos presupuestos personalizados basados en sus necesidades energéticas y las especificaciones de su propiedad, garantizando un precio preciso y competitivo."
        },
        "faq6": {
          "title": "¿Qué tipos de instalaciones ofrecen?",
          "content": "Nos especializamos en una variedad de soluciones energéticas, que incluyen:<ul><li>Instalación de paneles solares: Sistemas eficientes y rentables adaptados a su hogar.</li><li>Instalación de aire acondicionado: Unidades eficientes que mantienen su hogar cómodo todo el año.</li><li>Instalación de bombas de calor para piscinas: Extienda su temporada de baño con sistemas de calefacción ecológicos.</li><li>Instalación de calentadores de agua termodinámicos: Preparación sostenible de agua caliente para su hogar.</li><li>Instalación de estaciones de carga para vehículos eléctricos: Soluciones de carga convenientes para su vehículo eléctrico.</li></ul>Cada servicio está disponible en Calpe, Altea, Moraira, Benissa y las regiones circundantes."
        },
        "faq7": {
          "title": "¿Qué regiones atienden?",
          "content": "Estamos orgullosos de atender a propietarios y expatriados en toda la Costa Blanca, incluyendo:<ul><li>Calpe</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Ya sea que necesite soluciones de energía solar, instalaciones de aire acondicionado o estaciones de carga para vehículos eléctricos, ofrecemos servicios de alta calidad y confiabilidad en toda la región."
        },      
  contactButtonText: "¡Tu presupuesto en pocos clics!"

    }
  }
};
