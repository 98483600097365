import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Result.css';

import solarinstallation from '../assets/solarpanelinstallation.jpg';
import heaterinstallation from '../assets/heaterinstallation.jpg';
import electricinstallation from '../assets/electricinstallation.jpg';
import airheaterinstallation from '../assets/airheaterinstallation.jpg';
import poolheaterinstallation from '../assets/poolheaterinstallation.jpg';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Result = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-100">
      <Navbar />
      <div className="relative top-10">
      <div className="min-h-screen bg-gradient-to-b from-white to-[#f0f4f2] py-20 px-5 md:px-20">

      <div className="services-container">
        <div className="text-center mb-14">
          <h1 style={{ color: "#5b8251", textAlign: "center", lineHeight: "1.5", fontSize: "2rem", fontWeight: "bold" }}>
            {t("result.title")}
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-1 rounded-md"></span>
          </h1>
        </div>

        {t("result.services", { returnObjects: true }).map((service, index) => (
          <div className="service-section" key={index}>
            {index % 2 === 0 ? (
              <>
                <div className="service-content">
                  <div className="service-text">
                    <h2>{service.title}</h2><br />
                    <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
                    <Link to="/form">
                      <button className="service-button" onClick={scrollToTop}>{service.button}</button>
                    </Link>
                  </div>
                </div>
                <div className="service-image service-image-right">
                  <img src={index === 0 ? solarinstallation : index === 1 ? heaterinstallation : index === 2 ? poolheaterinstallation : index === 3 ? airheaterinstallation : electricinstallation} alt={service.altText} />
                </div>
              </>
            ) : (
              <>
                <div className="service-image service-image-left">
                  <img src={index === 0 ? solarinstallation : index === 1 ? heaterinstallation : index === 2 ? poolheaterinstallation : index === 3 ? airheaterinstallation : electricinstallation} alt={service.altText} />
                </div>
                <div className="service-content">
                  <div className="service-text">
                    <h2>{service.title}</h2><br />
                    <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
                    <Link to="/form">
                      <button className="service-button" onClick={scrollToTop}>{service.button}</button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}

      </div>
      </div>
      <Footer />
      </div>

    
    </section>
  );
};

export default Result;
