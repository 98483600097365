import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Form = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem("lang") || "en");
  const [formUrl, setFormUrl] = useState(
    "https://eco-green-projects.spondei.com/s/PtguZhST7K4=" // Default URL
  );

  useEffect(() => {
    // Update the form URL based on the current language
    if (language === "fr") {
      setFormUrl("https://eco-green-projects.spondei.com/s/g5iSNoowYWM=");
    } else {
      setFormUrl("https://eco-green-projects.spondei.com/s/PtguZhST7K4=");
    }

    // Ensure i18n syncs with language state
    i18n.changeLanguage(language);
  }, [language, i18n]);

  // Listen for language changes globally (e.g., from the Navbar)
  useEffect(() => {
    const handleLanguageChange = () => {
      const savedLanguage = localStorage.getItem("lang") || "en";
      setLanguage(savedLanguage);
    };

    window.addEventListener("storage", handleLanguageChange);

    return () => {
      window.removeEventListener("storage", handleLanguageChange);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="relative top-10">

      <div className="min-h-screen bg-gradient-to-b from-white to-[#f0f4f2] py-20 px-5 md:px-20">
        {/* Heading Section */}
        <div className="text-center mb-14">
          <h1
            style={{
              color: "#5b8251",
              textAlign: "center",
              lineHeight: "1.5",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-1 rounded-md"></span>
          </h1>
        </div>

        {/* Embedded form */}
        <div className="max-w-4xl mx-auto text-center text-gray-700 px-4 sm:px-6 md:px-10">
          <iframe
            id="sp-container-standard"
            src={formUrl}
            width="100%"
            style={{
              marginTop: "-100px",
              border: "none",
              width: "100%",
              height: "1200px",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          ></iframe>
          <script src="https://app.spondei.com/assets/js/embedCode.js"></script>
        </div>
      </div>
      <Footer />
      </div>
      
    </>
  );
};

export default Form;
