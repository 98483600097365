import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <div className="relative top-10">

      <div className="min-h-screen bg-gradient-to-b from-white to-[#f0f4f2] py-20 px-5 md:px-20">
        {/* Heading Section */}
        <div className="text-center mb-14">
          <h1
            style={{
              color: "#5b8251",
              textAlign: "center",
              lineHeight: "1.5",
              fontSize: "2rem",
              fontWeight: "bold"
            }}
          >
            {t("aboutpage.title")}
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-1 rounded-md"></span>
          </h1>
        </div>

        {/* Content Section */}
        <div
          className="max-w-5xl mx-auto text-xl md:text-2xl leading-relaxed text-gray-800 space-y-8"
          style={{ textAlign: "justify" }}
        >
          <p className="font-bold text-2xl md:text-3xl text-[#5b8251]">
            {t("aboutpage.heading")}
          </p>
          <p>{t("aboutpage.founder_info")}</p>
          <p>{t("aboutpage.savings")}</p>
          <p>{t("aboutpage.experience")}</p>

          <p className="font-bold text-xl">{t("aboutpage.services_title")}</p>
          <ul className="list-disc ml-10 space-y-4">
            {t("aboutpage.services", { returnObjects: true }).map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>

          <p>{t("aboutpage.closing")}</p>
        </div>
      </div>
      <Footer />
      </div>
      
    </>
  );
};

export default About;
