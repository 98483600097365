import React from "react";
import './Services.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the hook to handle translations
import solar from "../assets/solarpanel.webp";
import air from "../assets/airconditioning3.svg";
import pool from "../assets/waterheater.svg";
import heater from "../assets/heater3.svg";
import electric from "../assets/electriccar3.svg";
import solarinstallation from "../assets/solarpanelinstallation.jpg";
import heaterinstallation from "../assets/heaterinstallation.jpg";
import electricinstallation from "../assets/electricinstallation.jpg";

const Services = () => {
  const { t } = useTranslation(); // Use the hook to access translations

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <section className="services-section">
        <div className="relative">
          <h2 style={{ color: "#5b8251", textAlign: "center", lineHeight: "1.5", fontSize: "2rem", fontWeight: "bold" }}>
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-1 rounded-md"></span>
            {t('services.ourServicesTitle')}
          </h2>
        </div>

        {/* Icons section */}
        <div className="service-icons">
          <div className="service-icon">
            <a href="">
              <img src={solar} alt="Solar Panel" />
              <p>{t('services.solarPanelInstallation')}</p>
            </a>
          </div>
          <div className="service-icon">
            <a href="">
              <img src={air} alt="Air Conditioning" />
              <p>{t('services.airConditioningInstallation')}</p>
            </a>
          </div>
          <div className="service-icon">
            <a href="">
              <img src={pool} alt="Pool Heat Pump" />
              <p>{t('services.poolHeatPumpInstallation')}</p>
            </a>
          </div>
          <div className="service-icon">
            <a href="">
              <img src={heater} alt="Heat Pump Boiler" />
              <p>{t('services.heatPumpBoilerInstallation')}</p>
            </a>
          </div>
          <div className="service-icon">
            <a href="">
              <img src={electric} alt="Charging Station" />
              <p>{t('services.electricCarChargingStation')}</p>
            </a>
          </div>
        </div>

        {/* View all services button */}
        <div className="button-container">
          <div className="view-all-btn1">
            <button className="bg-[#6fb962] text-white px-6 py-2 rounded-md hover:bg-white hover:text-[#6fb962] hover:border-[#6fb962] border-transparent border-2 transition-all duration-300">
            <Link to="/services" onClick={scrollToTop}><button>{t('services.serviceDetailButton')}</button></Link>
            </button>
          </div>
          <div className="view-all-btn1">
          <button className="bg-[#6fb962] text-white px-6 py-2 rounded-md hover:bg-white hover:text-[#6fb962] hover:border-[#6fb962] border-transparent border-2 transition-all duration-300">
            <Link to="/form" onClick={scrollToTop}><button>{t('services.freeQuoteButton')}</button></Link>
            </button>
          </div>
        </div>

      </section>

      {/* Services with background images */}
      <div className="service-backgrounds">
        <div className="service-item" style={{ backgroundImage: `url(${solarinstallation})` }}>
          <h3>{t('services.friendlyPersonalService')}</h3>
        </div>
        <div className="service-item" style={{ backgroundImage: `url(${heaterinstallation})` }}>
          <h3>{t('services.installationExperience')}</h3>
        </div>
        <div className="service-item" style={{ backgroundImage: `url(${electricinstallation})` }}>
          <h3>{t('services.available')}</h3>
        </div>
      </div>
    </>
  );
};

export default Services;
